import React, { Component } from 'react';
import history from '../../helpers/history';

const articles = {
  'flamingo-returns-to-auckland': {
    type: 'NEWS',
    date: '23 September 2024',
    content: <>
      <p className="fm-news-article-text">Flamingo, the proudly Kiwi-owned and operated micromobility company, is excited to announce its return to Auckland with a newly awarded licence to operate. This significant launch marks a key milestone in our mission to enhance sustainable transport across New Zealand and we are thrilled to reconnect with our Auckland-based customers.</p>
      <img className="fm-news-article-img" alt="Flamingo Auckland" src="https://storage.googleapis.com/flamingo-static/images/facade/auckland-launch.jpg" />
      <p className="fm-news-article-text">Flamingo has been licensed for a two-year period starting 4 November 2024 and is one of two operators selected by Auckland Council from seven licence applications. The council chose Flamingo for our demonstrated commitment to safety initiatives and reducing nuisance, highlighting our focus on community well-being. With a cap of 1,400 scooters across the city, including seated scooters, we are dedicated to providing a safe, convenient and eco-friendly option that encourages a sustainable mode shift in urban transport.</p>
      <p className="fm-news-article-text">"We are excited to bring our services back to Auckland," said Jacksen Love, Co-Founder and Chief Executive of Flamingo. "As a locally owned company, we understand the unique needs of Kiwi communities and are dedicated to providing a reliable and sustainable transport option."</p>
      <p className="fm-news-article-text">Our commitment to value, safety and community engagement shapes our operations in Auckland. We are focused on ensuring faster response times for rectifying non-compliant parking, enhancing the experience for all of Auckland.</p>
      <p className="fm-news-article-text">Flamingo's electric scooters will be accessible throughout Auckland, providing an efficient and enjoyable way to navigate the city. In collaboration with Auckland Council, we aim to ensure a seamless integration of our services that benefits all residents. We are dedicated to building strong community relationships and welcome feedback to continually improve our service.</p>
      <p className="fm-news-article-text">Auckland residents will soon benefit from New Zealand's most competitively priced electric scooter sharing service. With a cost of just $1 to unlock and 45 cents per minute, our affordable pricing is designed to encourage widespread adoption and promote sustainable transportation options. Holders of Community Services Cards, SuperGold Cards, Hāpai Access, and Total Mobility Cards will enjoy a 50% discount under the Flamingo Community Discount Plan. Additionally, we are committed to supporting the student community with a special Student Discount Plan for all students holding a valid Student ID.</p>
      <p className="fm-news-article-text">For more information about Flamingo's launch in Auckland and to stay updated on upcoming events and promotions, please visit Flamingo's website and follow us on social media.</p>
    </>,
    title: 'Flamingo Returns to Auckland',
  },
  'dunedin-launch': {
    type: 'NEWS',
    date: '7 June 2024',
    content: <>
      <p className="fm-news-article-text">Flamingo, a proudly Kiwi-owned and operated micromobility company, is thrilled to announce its expansion into Dunedin this July. This exciting launch marks a significant milestone in our mission to enhance sustainable transport across New Zealand.</p>
      <img className="fm-news-article-img" alt="Flamingo Dunedin" src="https://storage.googleapis.com/flamingo-static/images/facade/dunedin.jpg" />
      <p className="fm-news-article-text">Flamingo’s arrival in Dunedin is set to enhance the way residents and visitors travel across the city, offering a safe, convenient and eco-friendly alternative to traditional transport methods. The partnership between Flamingo and Dunedin City Council underscores a shared commitment to enhancing sustainable transport and reducing the city's carbon footprint.</p>
      <p className="fm-news-article-text">"We are excited to bring our services to Dunedin," said Jacksen Love, Co-Founder and Chief Executive of Flamingo. "As a locally owned company, we understand the unique needs of Kiwi communities and are dedicated to offering a reliable and sustainable transport option. Our partnership with Dunedin City Council is a testament to our shared vision of a greener future for Dunedin."</p>
      <p className="fm-news-article-text">Ros MacGill, Dunedin City Council’s Manager Compliance Solutions, says the council went through a tender process to select Flamingo as the city’s new electric scooter provider.</p>
      <p className="fm-news-article-text">“We’re excited by the approach this Kiwi-owned company will bring, and we’re impressed by their commitment to value, safety, and giving back to our local community. And with an increased range that will include suburbs such as Port Chalmers and Green Island, electric scooter lovers will be able to spread their wings further than before.”</p>
      <p className="fm-news-article-text">Flamingo's electric scooters will be available across Dunedin, offering an efficient and enjoyable way to navigate the city. As part of this partnership, Flamingo and the Dunedin City Council will work together to ensure the integration of this service is seamless and beneficial for all residents. Flamingo is committed to fostering a positive relationship with the local community and is eager to receive feedback to continually improve the service.</p>
      <p className="fm-news-article-text">Dunedin residents will soon benefit from the most competitively priced electric scooter sharing service in New Zealand. With a cost of just $1 to unlock and 45 cents per minute, this affordable pricing is designed to encourage widespread adoption and a shift towards sustainable transportation options. Holders of Community Services Cards, SuperGold Cards, and Total Mobility Cards will enjoy a 50% discount under the Flamingo Community Discount Plan. Flamingo is also committed to supporting the student community, offering a special Student Discount Plan for all students with a valid Student ID.</p>
      <p className="fm-news-article-text">The collaboration between Flamingo and Dunedin City Council is a testament to the power of local partnerships in driving innovation and sustainability. Together, we are paving the way for a more connected and eco-friendly future in Dunedin.</p>
      <p className="fm-news-article-text">To celebrate the launch in Dunedin, Flamingo will be hosting a special safety event at The Octagon on Friday 5th July. Join us between 1pm and 4pm to meet the Flamingo team, learn how to safely ride and park Flamingo scooters and enjoy free ride credit and Flamingo socks!</p>
      <p className="fm-news-article-text">For more information about Flamingo's launch in Dunedin and to stay updated on upcoming events and promotions, please visit Flamingo's website and follow us on social media.</p>
    </>,
    title: 'Flamingo to Launch in Dunedin This July',
  },
  'flamingo-gen-5': {
    type: 'NEWS',
    date: '16 November 2023',
    content: <>
      <p className="fm-news-article-text">Flamingo, New Zealand’s largest Kiwi owned and operated micromobility company, is thrilled to announce the launch of its revolutionary Gen 5 scooters in Wellington this week. Boasting advanced safety features and cutting-edge technology, the Flamingo Gen 5 scooter redefines the micromobility landscape.</p>
      <img className="fm-news-article-img" alt="Flamingo Gen 5 Scooter" src="https://storage.googleapis.com/flamingo-static/images/email/flamingo-5-wellington-email.jpg" />
      <p className="fm-news-article-text">Key Safety Features of Flamingo Gen 5 Scooters:</p>
      <ul className="fm-news-article-list">
        <li className="fm-news-article-list-item"><b>Onboard Camera for Footpath & Pedestrian Detection:</b> The integration of onboard cameras enables the Gen 5 scooters to detect pedestrians and monitor adherence to specific regulations, such as the prohibition of riding on the footpath of the Golden Mile in Wellington.</li>
        <li className="fm-news-article-list-item"><b>Audible Alerts:</b> The Flamingo Gen 5 scooters come equipped with audible safety alerts which are conveyed via an integrated speaker system enhancing safety awareness for riders.</li>
        <li className="fm-news-article-list-item"><b>Integrated Phone Holder:</b> The built-in phone holder allows riders to navigate with ease while keeping their hands securely on the handlebars.</li>
        <li className="fm-news-article-list-item"><b>Turning Signals:</b> Elevating safety standards, Flamingo Gen 5 scooters are equipped with turning signals to enhance communication between riders and other road users.</li>
        <li className="fm-news-article-list-item"><b>Larger Front Wheel:</b> The Flamingo Gen 5 scooters introduce a 15% larger front wheel, providing riders with enhanced stability and control, ensuring a safer journey through Wellington's streets.</li>
        <li className="fm-news-article-list-item"><b>Realtime Geofencing:</b> Flamingo introduces cutting-edge realtime geofencing technology where zones are computed against the scooter's location twice a second, resulting in instant enforcement of geofence safety zones.</li>
      </ul>
      <p className="fm-news-article-text">Flamingo is a trailblazer in sustainable urban mobility, dedicated to providing innovative, safe and responsible transportation solutions for cities across New Zealand. The Flamingo Gen 5 scooter represents the company's commitment to pushing the boundaries of urban transportation.</p>
      <p className="fm-news-article-text">Since launching in Wellington in 2019, Wellingtonians have taken over 2.3 million rides with Flamingo. “As a Kiwi owned company we’re stoked with the support we’ve received over the past four and a half years. We’re thrilled to now be offering Wellington the most advanced sharing e-scooter in the world.” says Jacksen Love, Flamingo Co-Founder and Chief Executive.</p>
      <p className="fm-news-article-text">Flamingo recognises the importance of adapting to local rules and regulations. The Gen 5 scooters' onboard camera technology allows Flamingo to proactively comply with Wellington's requirements, such as the prohibition of riding electric scooters on the footpath of The Golden Mile (Courtney Place, Manners Street, Willis Street and Lambton Quay). Flamingo will be using machine learning algorithms over the coming months to adapt the footpath detection to The Golden Mile.</p>
      <p className="fm-news-article-text">“We believe it is of utmost importance to collaborate closely with local councils and tailor our service offering to the unique needs of each city” says Love.</p>
    </>,
    title: 'Flamingo Launches Latest Model Scooter in Wellington',
  }
};

class News extends Component {
  componentDidMount() {
    const title = articles[this.props.match.params.article] ? articles[this.props.match.params.article].title : 'News';
    document.title = `${title} | Flamingo`;
  }

  render() {
    const article = articles[this.props.match.params.article];
    if (!article) {
      history.push('/');
      return null;
    }

    return (
      <div className="fm-news-article">
        <p className="fm-news-article-pre">{ article.type }</p>
        <p className="fm-news-article-title">{ article.title }</p>
        <p className="fm-news-article-date">{ article.date }</p>
        { article.content }
      </div>
    );
  }
}

export default News;
