import React, { Component } from 'react';
import ContactForm from './components/contact-form';
import ContactQuick from './components/contact-quick';

class Contact extends Component {
  componentDidMount() {
    document.title = 'Contact | Flamingo';
  }

  render() {
    return (
      <div className="fm-contact">
        <div className="fm-contact-container">
          <p className="fm-contact-title">Contact</p>
          <div className="fm-contact-items">
            <ContactForm />
            <ContactQuick />
          </div>
        </div>
      </div>
    );
  }
}

export default Contact;
