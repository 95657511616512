import React, { Component } from 'react';

class JoinPlanImage extends Component {
  render() {
    const { name, description } = this.props;
    return (
      <div className="fm-join-plan-image">
        <div className="fm-join-plan-image-background">
          <div className="fm-join-plan-image-background-box"></div>
          <div className="fm-join-plan-image-background-img" style={{ backgroundImage: `url('https://storage.googleapis.com/flamingo-static/images/facade/join-plan.jpg')` }}></div>
        </div>
        <div className="fm-join-plan-image-content">
          <div className="fm-join-plan-image-content-box">
            <p className="fm-join-plan-image-title">Workplace Invitation</p>
            <p className="fm-join-plan-image-description">{ description || `You've been invited to join ${ name || 'your workplace' } on Flamingo.` }</p>
          </div>
        </div>
      </div>
    );
  }
}

export default JoinPlanImage;
