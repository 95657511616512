import React, { Component } from 'react';

class JoinPlanBenefits extends Component {
  render() {
    const items = [
      { label: 'Free', description: 'All Flamingo workplace sessions are completely free.', icon: 'plan-bike.png' },
      { label: 'Sustainable', description: 'Encourage active and sustainable transport across your team.', icon: 'plan-sustainable.png' },
      { label: 'Team Building', description: 'A great way to bring your team together and explore your city.', icon: 'plan-team.png' },
    ];
    return (
      <div className="fm-workplace-session-benefits">
        <div className="fm-workplace-session-benefits-items">
          {
            items.map((item, i) => {
              return (
                <div className="fm-workplace-session-benefits-item" key={i}>
                  <div className="fm-workplace-session-benefits-item-image" style={{ backgroundImage: `url('https://storage.googleapis.com/flamingo-static/images/facade/${item.icon}')` }}></div>
                  <p className="fm-workplace-session-benefits-item-title">{ item.label }</p>
                  <p className="fm-workplace-session-benefits-item-description">{ item.description }</p>
                </div>
              );
            })
          }
        </div>
      </div>
    );
  }
}

export default JoinPlanBenefits;
