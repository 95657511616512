import React, { Component } from 'react';

class JoinPlanBenefits extends Component {
  render() {
    if (!this.props.items) {
      return null;
    }
    return (
      <div className="fm-join-plan-benefits">
        <div className="fm-join-plan-benefits-items">
          {
            this.props.items.map((item, i) => {
              return (
                <div className="fm-join-plan-benefits-item" key={i}>
                  <div className="fm-join-plan-benefits-item-image" style={{ backgroundImage: `url('${item.iconUrl}')` }}></div>
                  <p className="fm-join-plan-benefits-item-title">{ item.title }</p>
                  <p className="fm-join-plan-benefits-item-description">{ item.description }</p>
                </div>
              );
            })
          }
        </div>
      </div>
    );
  }
}

export default JoinPlanBenefits;
