import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Cities extends Component {
  componentDidMount() {
    document.title = 'Cities | Flamingo';
  }

  render() {
    return (
      <div className="fm-cities">
        <div className="fm-cities-container">
          <p className="fm-cities-title">Cities</p>
          <div className="fm-cities-items">
            <Link className="fm-cities-item" to="/city/auckland">
              <div className="fm-cities-item-image" style={{ backgroundImage: `url('https://storage.googleapis.com/flamingo-static/images/facade/flamingo-auckland.jpg')` }}></div>
              <p className="fm-cities-item-title">Auckland</p>
            </Link>
            <Link className="fm-cities-item" to="/city/wellington">
              <div className="fm-cities-item-image" style={{ backgroundImage: `url('https://storage.googleapis.com/flamingo-static/images/facade/wellington-waterfront.jpg')` }}></div>
              <p className="fm-cities-item-title">Wellington</p>
            </Link>
            <Link className="fm-cities-item" to="/city/dunedin">
              <div className="fm-cities-item-image" style={{ backgroundImage: `url('https://storage.googleapis.com/flamingo-static/images/facade/dunedin.jpg')` }}></div>
              <p className="fm-cities-item-title">Dunedin</p>
            </Link>
            <Link className="fm-cities-item" to="/city/palmerston-north">
              <div className="fm-cities-item-image" style={{ backgroundImage: `url('https://storage.googleapis.com/flamingo-static/images/facade/palmy.jpg')` }}></div>
              <p className="fm-cities-item-title">Palmerston North</p>
            </Link>
            <Link className="fm-cities-item" to="/city/porirua">
              <div className="fm-cities-item-image" style={{ backgroundImage: `url('https://storage.googleapis.com/flamingo-static/images/facade/porirua-scooter-2.jpg')` }}></div>
              <p className="fm-cities-item-title">Porirua</p>
            </Link>
            <Link className="fm-cities-item" to="/city/waimakariri">
              <div className="fm-cities-item-image" style={{ backgroundImage: `url('https://storage.googleapis.com/flamingo-static/images/facade/waimakariri.jpg')` }}></div>
              <p className="fm-cities-item-title">Waimakariri</p>
            </Link>
            <div className="fm-cities-item"></div>
            {
              /*
              <Link className="fm-cities-item" to="/city/christchurch">
                <div className="fm-cities-item-image" style={{ backgroundImage: `url('https://storage.googleapis.com/flamingo-static/images/facade/christchurch.jpg')` }}></div>
                <p className="fm-cities-item-title">Christchurch</p>
              </Link>
              */
            }
          </div>
        </div>
      </div>
    );
  }
}

export default Cities;
