import React, { Component } from 'react';

class Blurb extends Component {
  render() {
    return (
      <div className="fm-about-blurb">
        <div className="fm-about-blurb-container">
          <p className="fm-about-blurb-text">Founded in 2018, Flamingo's mission is to provide safe and sustainable transport options accessible to everyone. Our scooters and bikes play a key role in keeping our cities moving efficiently.</p>
        </div>
      </div>
    );
  }
}

export default Blurb;
