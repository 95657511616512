import React, { Component } from 'react';

class SplashImage extends Component {
  render() {
    return (
      <div className="fm-city-splash-image">
        <div className="fm-city-splash-image-background">
          <div className="fm-city-splash-image-background-box"></div>
          <div className="fm-city-splash-image-background-img" style={{ backgroundImage: `url('${ this.props.city.image }')` }}></div>
        </div>
        <div className="fm-city-splash-image-content">
          <div className="fm-city-splash-image-content-box">
            <p className="fm-city-splash-image-title">{ this.props.city.name }</p>
            <p className="fm-city-splash-image-description">{ this.props.city.catch }</p>
          </div>
        </div>
      </div>
    );
  }
}

export default SplashImage;
