import React, { Component } from 'react';
import PromoImage from './components/promo-image';
import PromoDetails from './components/promo-details';
import history from '../../helpers/history';

const promotions = {
  football: {
    title: 'Heading to the game?',
    image: 'https://storage.googleapis.com/flamingo-static/images/facade/hero2.jpg',
    subheading: 'How to Redeem',
    terms: 'Promotion ends 11 August 2023. 1 per customer. $1 unlocking fee still applies. Only valid for trips on Flamingo electric scooters and bikes in Wellington. This voucher has no monetary value.',
    text: `Get 10 minutes FREE with Flamingo.`,
    description: <>Enter the voucher code "<b>FOOTBALL</b>" from the Payment section of the Flamingo app for 10 minutes FREE!</>,
    download: true,
  },
  palmy: {
    title: 'Need a new ride?',
    image: 'https://storage.googleapis.com/flamingo-static/images/facade/hero2.jpg',
    subheading: 'How to Redeem',
    terms: 'Only available to new users. Must redeem the code PINKPN before starting a trip. Only valid for trips on Flamingo scooters in Palmerston North. 1 per customer. Redeem in the Payment tab of the Flamingo app. Subject to availability. Limited time only. This voucher has no monetary value.',
    text: `Get $5 FREE on your first ride with Flamingo Palmy!`,
    description: <>Enter the voucher code "<b>PINKPN</b>" from the Payment section of the Flamingo app for $5 FREE credit!</>,
    download: true,
  },
  'newtown-to-city': {
    title: 'Newtown to City',
    image: 'https://storage.googleapis.com/flamingo-static/images/facade/how-to-bike-2.jpg',
    subheading: 'Try the new Newtown to City cycleway with Flamingo',
    terms: 'Promotion ends 31 October 2023. 1 per customer. $1 unlocking fee still applies. Only valid for trips on Flamingo electric scooters and bikes in Wellington. This voucher has no monetary value.',
    text: `Get 20 minutes FREE with Flamingo to try the new cycleway.`,
    description: <>Enter the voucher code "<b>N2C</b>" from the Payment section of the Flamingo app for 20 minutes FREE!</>,
    download: true,
  },
  advent: {
    title: 'Advent Calendar',
    image: 'https://storage.googleapis.com/flamingo-static/images/facade/promo-advent.jpg',
    subheading: 'How to Play',
    terms: '1 prize per person each calendar day from 1 December 2023 through to 25 December 2023. $1 unlocking fee still applies for free minute vouchers. Minute, unlock and credit vouchers are valid for 24 hours. Prizes may take up to 10 minutes to be applied to your account. Flamingo reserves the right to remove prizes from accounts that break these conditions.',
    text: `Win daily prizes with Flamingo this festive season!`,
    description: <>Tap the pink banner at the top of the Flamingo app to open your Flamingo Advent Calendar. Select todays door to reveal your <b>daily prize of FREE Flamingo credit</b>, but make sure to use it quick, each prize is valid for 24 hours!</>,
    download: true,
  },
  'summer-cycles': {
    title: 'Summer Cycles',
    image: 'https://storage.googleapis.com/flamingo-static/images/facade/how-to-bike-2.jpg',
    subheading: 'How to Redeem',
    terms: '1 prize per person each calendar day from 1 January 2023 through to 31 January 2023. $1 unlocking fee still applies. Vouchers are valid for 24 hours. Prizes may take up to 10 minutes to be applied to your account. Flamingo reserves the right to remove prizes from accounts that break these conditions.',
    text: `Get FREE credit daily on Flamingo electric bikes!`,
    description: <>Tap the pink banner at the top of the Flamingo app, then spin the wheel to win free credit each day! But make sure to use it quick, each credit is valid for 24 hours!</>,
    download: true,
  },
  'try-bike': {
    title: 'Try Our Electric Bike',
    image: 'https://storage.googleapis.com/flamingo-static/images/facade/how-to-bike.jpg',
    subheading: 'How to Redeem',
    terms: 'Promotion ends 31 January 2024. 1 per customer. $1 unlocking fee still applies. Only valid for trips on Flamingo electric bikes in Wellington. This voucher has no monetary value.',
    text: `Get 20 minutes FREE with Flamingo electric bikes.`,
    description: <>Enter the voucher code "<b>TRY20</b>" from the Payment section of the Flamingo app for 20 minutes FREE!</>,
    download: true,
  },
  abc: {
    title: 'Workplace E-Bike Training',
    image: 'https://storage.googleapis.com/flamingo-static/images/facade/how-to-bike-2.jpg',
    subheading: 'Support Active Transport in your Team',
    text: `Aotearoa Bike Challenge 2024`,
    description: <>As part of the Aotearoa Bike Challenge 2024, Flamingo are holding <b>FREE workplace e-bike training sessions</b>.<br/><br/>Register your workplace for a training session this February in Wellington. Each session includes a safety briefing and the opportunity for everyone on your team to ride a Flamingo e-bike ride. Spaces are limited, so make sure to get in quick!</>,
    button: { url: 'https://docs.google.com/forms/d/e/1FAIpQLScvWQ2w1hTxM55N4LJsmbGvuLpApUEbtgyWG_5oMNopec-ehw/viewform', label: 'Register for a Session' },
  },
  roadsafetyweek: {
    title: 'Road Safety Week',
    image: 'https://storage.googleapis.com/flamingo-static/images/facade/road.jpg',
    subheading: 'Practice riding safely this weekend and get 20 mins FREE!',
    text: `Keep our roads safe for everyone in the community.`,
    description: <>In support of Road Safety Week, Flamingo are holding a safety event between 10am and 12pm this Saturday (25 May) on the corner of Cable Street and Oriental Parade. Come down to learn how to safely ride and park our Flamingo scooters. Plus, grab some FREE Flamingo credit and stylish socks to keep you riding in style!</>,
    button: { url: 'https://www.facebook.com/events/1066416071129408', label: 'View Event on Facebook' },
  },
  dunedin: {
    title: 'Kia ora Dunedin',
    image: 'https://storage.googleapis.com/flamingo-static/images/facade/dunedin.jpg',
    subheading: 'How to Redeem',
    terms: 'Promotion ends 14 July 2024. 1 per customer. $1 unlocking fee still applies. Only valid for trips on Flamingo electric scooters in Dunedin. This voucher has no monetary value.',
    text: `Try Flamingo with 20 minutes FREE!`,
    description: <>Enter the voucher code "<b>DUNEDIN20</b>" from the Payment section of the Flamingo app for 20 minutes FREE!</>,
    download: true,
  },
  'dunedin-game': {
    title: 'Heading to the Game?',
    image: 'https://storage.googleapis.com/flamingo-static/images/facade/dunedin.jpg',
    subheading: 'How to Redeem',
    terms: 'Promotion ends 7 July 2024. 1 per customer. $1 unlocking fee still applies. Only valid for trips on Flamingo electric scooters in Dunedin. This voucher has no monetary value.',
    text: `Take Flamingo with 10 minutes FREE!`,
    description: <>Enter the voucher code "<b>GAME</b>" from the Payment section of the Flamingo app for 10 minutes FREE!<br/>Make sure to check the Flamingo app for parking guidelines at Forsyth Barr Stadium.</>,
    download: true,
  },
};

class Promo extends Component {
  constructor(props) {
    super(props);
    this.state = { promo: promotions[props.match.params.id] };
  }

  componentDidMount() {
    if (!this.state.promo) {
      history.push('/');
    } else {
      document.title = `${this.state.promo.title} | Flamingo`;
    }
  }

  render() {
    if (!this.state.promo) {
      return null;
    }

    return (
      <div className="fm-promo">
        <PromoImage promo={this.state.promo} />
        <PromoDetails promo={this.state.promo} />
      </div>
    );
  }
}

export default Promo;
