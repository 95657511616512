import React, { Component } from 'react';

class SafetyBlurb extends Component {
  render() {
    return (
      <div className="fm-safety-blurb">
        <div className="fm-safety-blurb-container">
          <p className="fm-safety-blurb-text">Safety is the top priority for Flamingo. We want to ensure everyone is kept safe whilst still maintaining a fun experience. You can find more information on safety guidelines for your city from our City Safety page below.</p>
        </div>
      </div>
    );
  }
}

export default SafetyBlurb;
