import React, { Component } from 'react';
import WLGImage from './components/wlg-image';
import WLGParking from './components/wlg-parking';
import WLGPromo from './components/wlg-promo';

class WellingtonAirport extends Component {
  componentDidMount() {
    document.title = 'Wellington Airport | Flamingo';
  }

  render() {
    return (
      <div className="fm-wlg">
        <WLGImage />
        <WLGParking />
        <WLGPromo />
      </div>
    );
  }
}

export default WellingtonAirport;
