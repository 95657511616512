import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Benefits extends Component {
  render() {
    return (
      <div className="fm-home-splash-benefits">
        <div className="fm-home-splash-benefits-boxes">
          <Link className="fm-home-splash-benefits-box-student" to="/discounts">
            <p className="fm-home-splash-benefits-box-student-title">Community & Student Discounts</p>
          </Link>
          <a className="fm-home-splash-benefits-box-ptz" href="https://shop.flamingoscooters.com/" target="_blank" rel="noopener noreferrer">
            <p className="fm-home-splash-benefits-box-ptz-title">Gift Cards & Merch</p>
          </a>
        </div>
      </div>
    );
  }
}

export default Benefits;
