import React, { Component } from 'react';
import PageHeading from '../common/page-heading';

class Terms extends Component {
  componentDidMount() {
    document.title = 'Terms | Flamingo';
  }

  render() {
    return (
      <div className="fm-terms">
        <PageHeading image="https://storage.googleapis.com/flamingo-static/images/facade/metal.jpg" title="Terms of Service" />
        <div className="fm-container fm-terms-content">
          <ol className="fm-terms-list">
            <li className="fm-terms-list-item fm-terms-list-item-top">
              <p className="fm-terms-list-header">Application of Terms</p>
              <ol className="fm-terms-list">
                <li className="fm-terms-list-item">
                  <p className="fm-terms-list-text">These Terms of Service govern your access and use of the Service (including the Scooters, Bikes and any other Flamingo Materials). By accepting these Terms or by using the Service, Scooters and/or Bikes, you acknowledge that you have read, understand and agree to be bound by these Terms.</p>
                </li>
                <li className="fm-terms-list-item">
                  <p className="fm-terms-list-text">If you do not agree to these Terms, you are not authorised to access and use the Service  (including the Scooters, Bikes and any other Flamingo Materials), and you must immediately stop doing so.</p>
                </li>
                <li className="fm-terms-list-item">
                  <p className="fm-terms-list-text">These Terms and the provision of the Services are contingent on Local Authority, Regional Authority and Government permission and consent which can be withdrawn or suspended at any time. You agree to comply with all Local Authority, Regional Authority and Government requirements and acknowledge that your failure to do so can cause Loss to Flamingo. You further acknowledge that these Terms may be altered without notice in response to Local Authority, Regional Authority and/or Government requirements at any time. In the event permission and consent to the provision of the Services is withdrawn or suspended by Local Authority, Regional Authority or Government you agree to comply with Flamingo’s reasonable requirements to ensure compliance with the requirements of Local Authority, Regional Authority and/or Government.</p>
                </li>
                <li className="fm-terms-list-item">
                  <p className="fm-terms-list-text">Breach of these Terms entitles Flamingo to immediately in the sole discretion of Flamingo disable any Scooters and/or Bikes you have hired and to disable, terminate or suspend your Account with or without notice.  </p>
                </li>
              </ol>
            </li>
            <li className="fm-terms-list-item fm-terms-list-item-top">
              <p className="fm-terms-list-header">Changes</p>
              <ol className="fm-terms-list">
                <li className="fm-terms-list-item">
                  <p className="fm-terms-list-text">We may change these Terms at any time by notifying you of the change by email or by posting a notice on the Website and/or App. Unless stated otherwise, any change takes effect from the date set out in the notice. You are responsible for ensuring you are familiar with the latest Terms. By continuing to access and use the Service from the date on which the Terms are changed, you agree to be bound by the changed Terms. Notwithstanding the preceding sentences of this clause, no revisions to these Terms will apply to any Dispute between you and Flamingo that arose prior to the date of such revision.</p>
                </li>
                <li className="fm-terms-list-item">
                  <p className="fm-terms-list-text">These Terms were last updated on the 30th day of January 2023.</p>
                </li>
              </ol>
            </li>
            <li className="fm-terms-list-item fm-terms-list-item-top">
              <p className="fm-terms-list-header">Definitions and Interpretation</p>
              <ol className="fm-terms-list">
                <li className="fm-terms-list-item">
                  <p className="fm-terms-list-text">Definitions - In these Terms unless a contrary intention appears:</p>
                  <ol className="fm-terms-list">
                    <li className="fm-terms-list-item">
                      <p className="fm-terms-list-text"><i>Account</i> means your personalised access to the App. You must register for an Account using your mobile phone number, or if we make such features and functionalities available, through certain third party social networking sites by using existing login credentials and passwords for sites such as Facebook.</p>
                    </li>
                    <li className="fm-terms-list-item">
                      <p className="fm-terms-list-text"><i>Bike or Bikes</i> means the electric bikes made available by Flamingo for provision of the Services. You do not, by these Terms or otherwise, acquire any ownership rights or interest in the Bikes.</p>
                    </li>
                    <li className="fm-terms-list-item">
                      <p className="fm-terms-list-text"><i>Confidential Information</i> means commercial, financial, marketing, technical or other information of whatever nature including without limitation information relating to a Flamingo’s clients, business, sales, systems, procedures, know how, Intellectual Property, trade secrets, commercial position, commercial opportunities  and other information in any form whether disclosed orally, electronically, in writing or otherwise before or after the date of these Terms.</p>
                    </li>
                    <li className="fm-terms-list-item">
                      <p className="fm-terms-list-text"><i>Dispute</i> or <i>Disputes</i> means any dispute, claim, or controversy between you and Flamingo, regardless of when it accrued that refers to, relates to or in any way involves your use of a Scooter, a Bike and/or the Service regardless of whether the Dispute is based in contract, statute, regulation, ordinance, tort (including fraud, misrepresentation, fraudulent inducement, or negligence), or on any other legal or equitable basis. It is to be given the broadest possible meaning that will be enforced, encompasses all Disputes regardless of when they accrued and is therefore expressly intended to be retroactive.</p>
                    </li>
                    <li className="fm-terms-list-item">
                      <p className="fm-terms-list-text"><i>Feedback</i> means any feedback, comments, questions, or suggestions concerning Flamingo or the Service.</p>
                    </li>
                    <li className="fm-terms-list-item">
                      <p className="fm-terms-list-text"><i>Flamingo</i>, <i>we</i>, <i>us</i> or <i>our</i> means Flamingo Technologies Limited, company number 7026913. These Terms are intended to benefit all entities related to Flamingo Technologies Limited. Therefore, reference to “Flamingo” includes Flamingo Technologies Limited, its successors, parent, affiliates, subsidiaries, officers and related entities.  However, in connection with any obligation owed to you under these Terms, reference to "Flamingo” is a reference to Flamingo Technologies Limited exclusively. For the purposes of section12 of the Contract and Commercial Law Act 2017 the successors, parent, affiliates, subsidiaries, officers and related entities are beneficiaries.</p>
                    </li>
                    <li className="fm-terms-list-item">
                      <p className="fm-terms-list-text"><i>Flamingo App</i>, <i>App</i> or <i>Application</i> is the mobile application that is used to provide the Service and may be available through certain third party marketplaces.</p>
                    </li>
                    <li className="fm-terms-list-item">
                      <p className="fm-terms-list-text"><i>Flamingo Materials</i> means any vehicles, devices, accessories (such as helmets or locks) or materials made available by Flamingo to you for use with the Service.</p>
                    </li>
                    <li className="fm-terms-list-item">
                      <p className="fm-terms-list-text"><i>Flamingo Software</i> means the software that is used to provide the Service.</p>
                    </li>
                    <li className="fm-terms-list-item">
                      <p className="fm-terms-list-text"><i>Intellectual Property</i> means all current or future rights including statutory and other proprietary rights in respect of copyright, inventions, patents, plant varieties, trade marks, trade names, designs, layouts, know-how, trade secrets, data, Confidential Information, and all similar or analogous intellectual property rights (as defined in Article 2 of the convention of 1967 establishing the World Intellectual Property Organisation) including any application for registration thereof, whether or not registered and whether or not capable of registration and for the removal of doubt includes the trade mark FLAMINGO and the FLAMINGO Logo, copyright in the Underlying Systems, Flamingo Software, the App, Website and the FLAMINGO Logo.</p>
                    </li>
                    <li className="fm-terms-list-item">
                      <p className="fm-terms-list-text"><i>Interest</i> means of 10% (ten percent) per annum above New Zealand’s Official Cash Rate at the time liability for the payment of Interest arises recognising that Flamingo is not in the business of providing credit.</p>
                    </li>
                    <li className="fm-terms-list-item">
                      <p className="fm-terms-list-text"><i>including</i> and similar words do not imply any limit.</p>
                    </li>
                    <li className="fm-terms-list-item">
                      <p className="fm-terms-list-text"><i>Loss</i> or <i>Losses</i> means loss of profits, loss of savings, consequential loss, loss of revenue, loss of data, the cost of damage to or replacement of all forms of property, the cost of liability that is payable or paid by the claimant on behalf of another party, legal costs on a solicitor and own client basis and any other harm suffered by the claimant including the costs to address a claim for Loss as defined in this definition and Interest on Loss.</p>
                    </li>
                    <li className="fm-terms-list-item">
                      <p className="fm-terms-list-text"><i>Personal Information</i> means information about an identifiable, living person.</p>
                    </li>
                    <li className="fm-terms-list-item">
                      <p className="fm-terms-list-text"><i>Scooter</i> or <i>Scooters</i> means the electric scooters made available by Flamingo for provision of the Services. You do not, by these Terms or otherwise, acquire any ownership rights or interest in the Scooters.</p>
                    </li>
                    <li className="fm-terms-list-item">
                      <p className="fm-terms-list-text"><i>Service</i> means the hireage of electric Scooters and Bikes provided by us via the App, Website and Flamingo Software.</p>
                    </li>
                    <li className="fm-terms-list-item">
                      <p className="fm-terms-list-text"><i>Terms</i> means these terms and conditions titled Terms of Service.</p>
                    </li>
                    <li className="fm-terms-list-item">
                      <p className="fm-terms-list-text"><i>Underlying System</i> or <i>Underlying Systems</i> means any network, firmware, hardware, system, software, data or material that underlies or is connected to or allows the Service, the Scooters, the Bikes, the Flamingo Software, the App and Website to operate.</p>
                    </li>
                    <li className="fm-terms-list-item">
                      <p className="fm-terms-list-text"><i>Website</i> means the internet site at https://rideflamingo.com, or such other site notified to you by us.</p>
                    </li>
                  </ol>
                </li>
                <li className="fm-terms-list-item">
                  <p className="fm-terms-list-text">Interpretation - in these Terms unless a contrary intention appears:</p>
                  <ol className="fm-terms-list">
                    <li className="fm-terms-list-item">
                      <p className="fm-terms-list-text">Words or expressions importing the singular include the plural and vice versa;</p>
                    </li>
                    <li className="fm-terms-list-item">
                      <p className="fm-terms-list-text">Words or expressions denoting individuals include corporations, firms, unincorporated bodies, government authorities and instrumentalities;</p>
                    </li>
                    <li className="fm-terms-list-item">
                      <p className="fm-terms-list-text">A reference to a party includes that party's successors and permitted assigns;</p>
                    </li>
                    <li className="fm-terms-list-item">
                      <p className="fm-terms-list-text">Where a word or expression is defined or given meaning, another grammatical form has a corresponding meaning;</p>
                    </li>
                    <li className="fm-terms-list-item">
                      <p className="fm-terms-list-text">A provision of these Terms will not be construed to the disadvantage of a party merely because that party was responsible for the preparation of these Terms or the inclusion of the provision in these Terms;</p>
                    </li>
                    <li className="fm-terms-list-item">
                      <p className="fm-terms-list-text">A reference to these Terms includes these Terms as amended, varied, novated, supplemented or replaced from time to time;</p>
                    </li>
                    <li className="fm-terms-list-item">
                      <p className="fm-terms-list-text">Any recitals, preliminary provisions, schedule or annexure form part of these Terms and have effect as if set out in full in the body of these Terms;</p>
                    </li>
                    <li className="fm-terms-list-item">
                      <p className="fm-terms-list-text">A reference to dollars or $ is a reference to New Zealand dollars; and</p>
                    </li>
                    <li className="fm-terms-list-item">
                      <p className="fm-terms-list-text">References to include and including are to be construed without limitation.</p>
                    </li>
                  </ol>
                </li>
                <li className="fm-terms-list-item">
                  <p className="fm-terms-list-text">No waiver - Without limiting any of Flamingo’s rights and remedies set out elsewhere in these Terms, a party waives a right under these Terms only by written notice that it waives that right.  A waiver is limited to the specific instance to which it relates and to the specific purpose for which it is given.</p>
                </li>
                <li className="fm-terms-list-item">
                  <p className="fm-terms-list-text">Severance - If a provision of these Terms would, but for this clause, be unenforceable:</p>
                  <ol className="fm-terms-list">
                    <li className="fm-terms-list-item">
                      <p className="fm-terms-list-text">the provision will be read down to the extent necessary to avoid that result; and</p>
                    </li>
                    <li className="fm-terms-list-item">
                      <p className="fm-terms-list-text">if the provision cannot be read down to that extent, it will be severed without affecting the validity and enforceability of the remainder of these Terms.</p>
                    </li>
                  </ol>
                </li>
                <li className="fm-terms-list-item">
                  <p className="fm-terms-list-text">Clauses which, by their nature, are intended to survive termination of these Terms, continue in force.</p>
                </li>
                <li className="fm-terms-list-item">
                  <p className="fm-terms-list-text">These Terms set out everything agreed by the parties relating to your use of the Service and supersede and cancel anything discussed, exchanged or agreed prior to you agreeing to these Terms. The parties have not relied on any representation, warranty or agreement relating to the Service that is not expressly set out in the Terms, and no such representation, warranty or agreement has any effect from the date you agreed to these Terms. Flamingo is not obligated to you under any other agreements unless they are in writing and signed by an authorised representative of Flamingo.</p>
                </li>
                <li className="fm-terms-list-item">
                  <p className="fm-terms-list-text">You agree that, except as otherwise expressly provided in these Terms, you will have no third party beneficiaries to these Terms and your use of the Services.</p>
                </li>
                <li className="fm-terms-list-item">
                  <p className="fm-terms-list-text">These Terms, and any dispute relating to these Terms or the Service, are governed by and must be interpreted in accordance with the laws of New Zealand.</p>
                </li>
                <li className="fm-terms-list-item">
                  <p className="fm-terms-list-text">The section titles in these Terms are for convenience only and have no legal or contractual effect.</p>
                </li>
              </ol>
            </li>
            <li className="fm-terms-list-item fm-terms-list-item-top">
              <p className="fm-terms-list-header">Eligibility</p>
              <ol className="fm-terms-list">
                <li className="fm-terms-list-item">
                  <p className="fm-terms-list-text">To be eligible to use the Service, you must own or control a mobile device that is compatible with the App and the Scooters or the Bikes. You are responsible for obtaining, at your own expense, such a mobile device and all other equipment and services needed to access the Service. You understand that your wireless and/or telecommunications carrier may charge certain fees (including those for data, text messaging, and other wireless access or communication services). Flamingo does not guarantee that the App can be accessed through all mobile devices or service plans or in all geographic locations.</p>
                </li>
                <li className="fm-terms-list-item">
                  <p className="fm-terms-list-text">To be eligible to use the Service, subject to clause 4.4 below regarding the limited use of Scooters and Bikes by minors, you certify and expressly agree that you are the sole hirer who will use the Scooter or the Bike activated by you or designated to you on a Group Ride per clause 10 and you are responsible for compliance with all clauses and provisions contained in these Terms.</p>
                </li>
                <li className="fm-terms-list-item">
                  <p className="fm-terms-list-text">Subject to clause 4.4, to be eligible to use the Service, you must be at least eighteen (18) years old and have the right and authority to agree to these Terms. You represent and certify that you are at least eighteen (18) years old.</p>
                </li>
                <li className="fm-terms-list-item">
                  <p className="fm-terms-list-text">You may allow a minor who is at least twelve (12) years of age to use a Scooter or Bike booked through your Account with your supervision, subject to the following conditions:</p>
                  <ol className="fm-terms-list">
                    <li className="fm-terms-list-item">
                      <p className="fm-terms-list-text">You are the minor’s parent or legal guardian;</p>
                    </li>
                    <li className="fm-terms-list-item">
                      <p className="fm-terms-list-text">You assume full financial and/or any other responsibility for any and all misuse, consequences, claims, demands, causes of action, Losses, liabilities, damages, injuries, fees, costs and expenses, penalties, attorneys’ fees, judgments, suits and/or disbursements of any kind, or nature whatsoever, whether foreseeable or unforeseeable, and whether known or unknown, as a result of the minor’s use of the Scooter and/or any of the Service;</p>
                    </li>
                    <li className="fm-terms-list-item">
                      <p className="fm-terms-list-text">You expressly guarantee, represent, and warrant that you and the minor are bound by these Terms and all of the provisions and clauses herein;</p>
                    </li>
                    <li className="fm-terms-list-item">
                      <p className="fm-terms-list-text">You expressly guarantee, represent, and warrant that you will supervise the minor at all times while the minor is using the Scooter or the Bike;</p>
                    </li>
                    <li className="fm-terms-list-item">
                      <p className="fm-terms-list-text">You expressly guarantee, represent, and warrant that you have instructed the minor how to conduct the requisite safety check of the Scooter or the Bike prior to use;</p>
                    </li>
                    <li className="fm-terms-list-item">
                      <p className="fm-terms-list-text">You expressly guarantee, represent, and warrant that the minor is wearing an approved bicycle helmet that has been properly sized, fitted and fastened according to the manufacturer’s instructions at all times while riding the Scooter or the Bike; and</p>
                    </li>
                    <li className="fm-terms-list-item">
                      <p className="fm-terms-list-text">You expressly guarantee, represent, and warrant that you have explained all applicable laws, rules, regulations, bylaws and/or policies to the minor, including those regarding parking and traffic laws.</p>
                    </li>
                  </ol>
                </li>
               <li className="fm-terms-list-item">
                  <p className="fm-terms-list-text">To be eligible to use the Service, you must be a safe and competent Scooter and/or Bike operator. You:</p>
                  <ol className="fm-terms-list">
                    <li className="fm-terms-list-item">
                      <p className="fm-terms-list-text">Represent and warrant that you have sufficient knowledge to operate and are sufficiently physically capable of operating an electric scooter and/or bike safely in compliance with all applicable laws, rules, regulations, bylaws and/or policies without any risk to the health and safety of yourself or others: </p>
                    </li>
                    <li className="fm-terms-list-item">
                      <p className="fm-terms-list-text">Will operate the Scooter and/or Bike safely without any risk to the health and safety of yourself or others including as required under clause 5;</p>
                    </li>
                    <li className="fm-terms-list-item">
                      <p className="fm-terms-list-text">Represent and warrant further that you will comply with all all applicable laws, rules, regulations, bylaws and/or policies relevant to your use of the Service, including but not limited to laws applicable to the operation of electric scooters and/or bikes of the jurisdiction within which you use the Service; and </p>
                    </li>
                    <li className="fm-terms-list-item">
                      <p className="fm-terms-list-text">Acknowledge that compliance with the foregoing provisions of this clause is essential to Flamingo’s willingness to hire a Scooter and/or Bike to you such that breach of this clause entitles Flamingo to immediately in the sole discretion of Flamingo disable a Scooter and/or Bike you have hired and to disable, terminate or suspend your Account without notice.</p>
                    </li>
                  </ol>
                </li>
                <li className="fm-terms-list-item">
                  <p className="fm-terms-list-text">To be eligible to use the Service, you represent and warrant to us that you have a clean driving record, as determined by Flamingo in its sole discretion, which means that, at minimum, you have none of the following: </p>
                  <ol className="fm-terms-list">
                    <li className="fm-terms-list-item">
                      <p className="fm-terms-list-text">any major traffic convictions in the past three (3) years; </p>
                    </li>
                    <li className="fm-terms-list-item">
                      <p className="fm-terms-list-text">any alcohol or drug related incidents up to seven (7) years prior to use of the Services; </p>
                    </li>
                    <li className="fm-terms-list-item">
                      <p className="fm-terms-list-text">more than two (2) minor traffic convictions in the past three (3) years; and </p>
                    </li>
                    <li className="fm-terms-list-item">
                      <p className="fm-terms-list-text">more than one (1) vehicle, scooter or bike accident in the past three (3) years.</p>
                    </li>
                  </ol>
                </li>
                <li className="fm-terms-list-item">
                  <p className="fm-terms-list-text">To be eligible to use the Service, you must allow Flamingo to track your movements whenever the App is running on your mobile device, to the extent permitted by law. (Flamingo requires information regarding your location and the location of the Scooters and the Bikes in order to provide the Service.) The use and collection of use information is governed by Flamingo’s Privacy Policy, found at https://rideflamingo.com/privacy. (Please refer to the Privacy Policy or email us at legal@rideflamingo.com for any questions about how we use location information.)</p>
                </li>
                <li className="fm-terms-list-item">
                  <p className="fm-terms-list-text">To be eligible to use the Service, you must agree to follow all rules and obligations set forth in clause 5, as may be updated from time to time. Any use of the Service that is prohibited by clause 5 or otherwise by these Terms may void all insurance, accident, or liability coverages (where permitted by law) and makes you responsible for all Loss or damage to, or connected with, your use of the Service, to the extent that such Loss or damage is connected to such prohibited use.</p>
                </li>
              </ol>
            </li>
            <li className="fm-terms-list-item fm-terms-list-item-top">
              <p className="fm-terms-list-header">Your Obligations</p>
              <ol className="fm-terms-list">
                <li className="fm-terms-list-item">
                  <p className="fm-terms-list-text">You must provide true, current and complete information in your dealings with us (including when setting up an Account) and must promptly update that information as required so that the information remains true, current and complete.</p>
                </li>
                <li className="fm-terms-list-item">
                  <p className="fm-terms-list-text">You are responsible for keeping your Account secure and must:</p>
                  <ol className="fm-terms-list">
                    <li className="fm-terms-list-item">
                      <p className="fm-terms-list-text">Not permit any other person to use your Account;</p>
                    </li>
                    <li className="fm-terms-list-item">
                      <p className="fm-terms-list-text">You must not disclose information that would allow any other person to access your Account; and</p>
                    </li>
                    <li className="fm-terms-list-item">
                      <p className="fm-terms-list-text">Immediately notify us if you become aware of any disclosure or unauthorised use of your Account, by sending an email to hello@rideflamingo.com. </p>
                    </li>
                  </ol>
                </li>
                <li className="fm-terms-list-item">
                  <p className="fm-terms-list-text">You are solely responsible for all activity that occurs on your Account, and you agree to notify Flamingo immediately of any suspected unauthorised use. To the maximum extent allowed by law, Flamingo is not liable for any Losses by any party caused by unauthorised use of your Account.</p>
                </li>
                <li className="fm-terms-list-item">
                  <p className="fm-terms-list-text">You agree that:</p>
                  <ol className="fm-terms-list">
                    <li className="fm-terms-list-item">
                      <p className="fm-terms-list-text">You may register for only one (1) Account at any point in time;</p>
                    </li>
                    <li className="fm-terms-list-item">
                      <p className="fm-terms-list-text">Your Account may not be transferred to any other person or entity; and</p>
                    </li>
                    <li className="fm-terms-list-item">
                      <p className="fm-terms-list-text">all rights to your Account cease upon death or other termination of your Account.</p>
                    </li>
                  </ol>
                </li>
                <li className="fm-terms-list-item">
                  <p className="fm-terms-list-text">Upon receipt of a certified copy of a death certificate, your Account will be terminated and its contents (if any) permanently deleted.</p>
                </li>
                <li className="fm-terms-list-item">
                  <p className="fm-terms-list-text">While Flamingo may attempt to verify age where required by law or to enforce these Terms, it does not currently conduct identify verification or background investigations (including criminal background). Flamingo does not attempt to verify the statements made by you. Flamingo reserves the right, but not the obligation, as permitted by applicable law, to obtain reports from authorised third party vendors that may be based on publicly available information, which may include credit reference checks, an ID checking service, history of criminal convictions, sex offender registry registration and/or other third parties from time to time, and Flamingo may, in its sole discretion, limit, block, suspend, deactivate, or cancel your Account based on the results of such a check. By using the Service, including the Scooters and the Bikes, you expressly authorise us to use Personal Information and other identifying information, such as your full name and date of birth, to obtain such reports from third party vendors should Flamingo determine to perform such verifications or checks and any subsequent re-verifications or checks. Any use of your personal information will be consistent with the terms of our Privacy Policy, found at https://rideflamingo.com/privacy.</p>
                </li>
                <li className="fm-terms-list-item">
                  <p className="fm-terms-list-text">You must:</p>
                  <ol className="fm-terms-list">
                    <li className="fm-terms-list-item">
                      <p className="fm-terms-list-text">Not act in a way, or use or introduce anything (including any virus, worm, Trojan horse, timebomb, keystroke logger, spyware, malware or other similar feature) into the Underlying System, the App, Website and Flamingo Software;</p>
                    </li>
                    <li className="fm-terms-list-item">
                      <p className="fm-terms-list-text">Not attempt to use or access the Underlying System, the App, Website and Flamingo Software in a manner that in any way compromises, or may compromise, the Service, Underlying System, the App, Website and Flamingo Software;</p>
                    </li>
                    <li className="fm-terms-list-item">
                      <p className="fm-terms-list-text">Unless with our agreement, only access the App and Website via standard web browsers and not by any other method;</p>
                    </li>
                    <li className="fm-terms-list-item">
                      <p className="fm-terms-list-text">Not access or attempt to access the Underlying system, the App, Website and Flamingo Software by methods such as scraping, deep-linking, harvesting, data mining, use of a robot or spider, automation, or any similar data gathering, extraction or monitoring method; and</p>
                    </li>
                    <li className="fm-terms-list-item">
                      <p className="fm-terms-list-text">Not interfere with or disrupt the Service, Underlying System, the App, Website and Flamingo Software.</p>
                    </li>
                  </ol>
                </li>
                <li className="fm-terms-list-item">
                  <p className="fm-terms-list-text">You must obtain our written permission to establish a link to our Website and/or App. If you wish to do so, email your request to legal@rideflamingo.com.</p>
                </li>
                <li className="fm-terms-list-item">
                  <p className="fm-terms-list-text">You agree that when you use the Service you will comply will all applicable laws and these Terms. In particular, but without limitation, you agree not to:</p>
                  <ol className="fm-terms-list">
                    <li className="fm-terms-list-item">
                      <p className="fm-terms-list-text">Use the Service for any unlawful or fraudulent purpose, including to represent yourself as or impersonate any other person or entity;</p>
                    </li>
                    <li className="fm-terms-list-item">
                      <p className="fm-terms-list-text">Solicit money from any other user of the Service, including any rider;</p>
                    </li>
                    <li className="fm-terms-list-item">
                      <p className="fm-terms-list-text">Take any action that could interfere with the normal operation of the Service or any Flamingo activity;</p>
                    </li>
                    <li className="fm-terms-list-item">
                      <p className="fm-terms-list-text">Impersonate any person or entity, or falsify or otherwise misrepresent yourself or your affiliation with any person or entity;</p>
                    </li>
                    <li className="fm-terms-list-item">
                      <p className="fm-terms-list-text">Express or imply that any statements you make are endorsed by Flamingo without our specific prior written consent;</p>
                    </li>
                    <li className="fm-terms-list-item">
                      <p className="fm-terms-list-text">Collect Account details and/or email addresses of other Flamingo users by electronic or other means for the purpose of sending unsolicited email or unauthorised framing or linking to the Flamingo Website or App;</p>
                    </li>
                    <li className="fm-terms-list-item">
                      <p className="fm-terms-list-text">Engage in any conduct that involves the transmission of junk mail, chain letters, or unsolicited mass mailing or spamming (or spimming, phishing, trolling or similar activities) to Flamingo or Flamingo users.</p>
                    </li>
                    <li className="fm-terms-list-item">
                      <p className="fm-terms-list-text">Use automated scripts to collect information from or otherwise interact with the Scooters, the Bikes, Flamingo Materials, Underlying System, the App, Website and/or Flamingo Software;</p>
                    </li>
                    <li className="fm-terms-list-item">
                      <p className="fm-terms-list-text">Stalk or harass any person;</p>
                    </li>
                    <li className="fm-terms-list-item">
                      <p className="fm-terms-list-text">Take video, audio, photographs, or images of another Flamingo user without his or her permission (or in the case of a minor, the minor’s legal guardian);</p>
                    </li>
                    <li className="fm-terms-list-item">
                      <p className="fm-terms-list-text">Access data of which you are not an intended recipient or log into a server or account on a network related to the Service, Underlying System, the App, Website and/or Flamingo Software that you are not expressly authorised to access;</p>
                    </li>
                    <li className="fm-terms-list-item">
                      <p className="fm-terms-list-text">Infringe any third party intellectual property rights;</p>
                    </li>
                    <li className="fm-terms-list-item">
                      <p className="fm-terms-list-text">Allow any other person to use your Account;</p>
                    </li>
                    <li className="fm-terms-list-item">
                      <p className="fm-terms-list-text">Take any action that may undermine the efficacy or accuracy of reviews or ratings systems maintained by Flamingo;</p>
                    </li>
                    <li className="fm-terms-list-item">
                      <p className="fm-terms-list-text">Provide instructional information about illegal activities including how to damage Flamingo property, Scooters, Bikes or the property of other Flamingo users or how to exploit the Service, Underlying System, the App, Website and/or Flamingo Software causing Loss to Flamingo;</p>
                    </li>
                    <li className="fm-terms-list-item">
                      <p className="fm-terms-list-text">Undertake any activity or engage in any conduct that is inconsistent with the business or purpose of the Service, or that is intended to promote or has the effect of engaging in illegal activities, fraud, or that is defamatory, libelous or otherwise objectionable;</p>
                    </li>
                    <li className="fm-terms-list-item">
                      <p className="fm-terms-list-text">Directly or indirectly promote racism, bigotry, hatred or physical harm of any kind against any group or individual;</p>
                    </li>
                    <li className="fm-terms-list-item">
                      <p className="fm-terms-list-text">Directly or indirectly request money from, or otherwise defraud, other Flamingo users;</p>
                    </li>
                    <li className="fm-terms-list-item">
                      <p className="fm-terms-list-text">Make or distribute copies of the Underlying System, the App, Website and Flamingo Software;</p>
                    </li>
                    <li className="fm-terms-list-item">
                      <p className="fm-terms-list-text">Attempt to copy, reproduce, alter, modify, reverse engineer, disassemble, decompile, transfer, exchange or translate the Underlying System, the App, Website and Flamingo Software;</p>
                    </li>
                    <li className="fm-terms-list-item">
                      <p className="fm-terms-list-text">Forge headers or otherwise manipulate identifiers in order to disguise the origin of any information transmitted to or through the Underlying System, the App, Website and Flamingo Software (either directly or indirectly through use of third party software);</p>
                    </li>
                    <li className="fm-terms-list-item">
                      <p className="fm-terms-list-text">Frame or mirror any part of the Service without Flamingo’s prior written authorisation;</p>
                    </li>
                    <li className="fm-terms-list-item">
                      <p className="fm-terms-list-text">Use meta tags or code or other devices containing any reference to Flamingo, the Service, Underlying System, the App, Website or Flamingo Software (or any of Flamingo’s trademarks, trade names, service marks, logos or slogans, domain names, metadata) to direct any person to any other website for any purpose;</p>
                    </li>
                    <li className="fm-terms-list-item">
                      <p className="fm-terms-list-text">Create derivative works based upon the Service, Underlying System, the App, Website and Flamingo Software of any kind whatsoever or any software or programming related thereto;</p>
                    </li>
                    <li className="fm-terms-list-item">
                      <p className="fm-terms-list-text">Post, use, transmit or distribute, directly or indirectly, (e.g. screen scrape) in any manner or media any content or information obtained from the Service, Underlying System, the App, Website or Flamingo Software other than solely in connection with your use of the Service in accordance with these Terms;</p>
                    </li>
                    <li className="fm-terms-list-item">
                      <p className="fm-terms-list-text">Provide information or data you do not have a right to make available under law or under contractual or fiduciary relationships (such as inside information, proprietary and confidential information);</p>
                    </li>
                    <li className="fm-terms-list-item">
                      <p className="fm-terms-list-text">Solicit passwords or personal identifying information for commercial, fraudulent or unlawful purposes from other users or disseminate another person’s Personal Information without their permission;</p>
                    </li>
                    <li className="fm-terms-list-item">
                      <p className="fm-terms-list-text">Use the Service, Underlying System, the App, Website and/or Flamingo Software for any commercial or competitive advantage, any illegal, defamatory or objectionable purpose, or any purpose which could bring the reputation of Flamingo into disrepute or otherwise cause Loss to Flamingo;</p>
                    </li>
                    <li className="fm-terms-list-item">
                      <p className="fm-terms-list-text">Attempt to indirectly engage in any of the above-listed conduct; or</p>
                    </li>
                    <li className="fm-terms-list-item">
                      <p className="fm-terms-list-text">Allow any third party to do any of these things.</p>
                    </li>
                  </ol>
                </li>
                <li className="fm-terms-list-item">
                  <p className="fm-terms-list-text">From time to time, the Service, Underlying System, the App, Website and/or Flamingo Software may contain links to websites, or the contact information of other persons. These links are provided for convenience only, and do not represent any endorsement by Flamingo or any other person of the products or services offered by the site owner or other person.</p>
                </li>
                <li className="fm-terms-list-item">
                  <p className="fm-terms-list-text">In some cases, we may partner with another company to co-promote their services within our Service. In these cases, you are transacting directly with the other party. On those pages or locations, the transactional partners’ brand is clearly visible and their terms and conditions are posted. When using these partner pages, you are bound by the partner terms and conditions in addition to remaining bound by these Terms. When there is a conflict between these Terms and the partner’s terms and conditions, our Terms will prevail.</p>
                </li>
                <li className="fm-terms-list-item">
                  <p className="fm-terms-list-text">When using the App, you agree to comply with any and all third party terms that are applicable to any platform, website, technology or service that interacts with the App.</p>
                </li>
                <li className="fm-terms-list-item">
                  <p className="fm-terms-list-text">We assume no responsibility for a user’s compliance with any agreements with or duties to other users, third parties, applicable laws, rules and regulations.</p>
                </li>
                <li className="fm-terms-list-item">
                  <p className="fm-terms-list-text">If you take part in any competition which is run through the Service, you agree to be bound by the rules of that competition and any other rules specified by Flamingo from time to time and by the decisions of Flamingo, which are final in all matters relating to the competition. Flamingo reserves the right to disqualify any entrant and/or winner in its absolute discretion without notice in accordance with the competition rules.</p>
                </li>
                <li className="fm-terms-list-item">
                  <p className="fm-terms-list-text">You must inspect the Scooter or the Bike and any Flamingo Materials and ensure they are in good condition working order prior to your use of a Service, and you should promptly inform Flamingo if any Scooters, Bikes or Flamingo Materials are found not to be in good condition or working order. If you discover any damage or disrepair, you must notify Flamingo immediately to avoid being held responsible for such damage or disrepair. If you fail to report any evidence of damage prior to using the Scooter or the Bike, you may be liable for the cost of repairing the Scooter or the Bike. For your own safety, you are prohibited from operating a Scooter or a Bike if any of the following equipment is not in good working order: helmet, tyres, brakes, lights, reflectors.</p>
                </li>
                <li className="fm-terms-list-item">
                  <p className="fm-terms-list-text">If at any time, whether prior to, during, or after riding any Scooter or Bike, you discover any defect or notice any other potentially unsafe condition on the Scooter or the Bike, no matter how slight, you must not use the Scooter or the Bike, or, if you are already riding the Scooter or the Bike, you must immediately cease riding when it is safe to do so. You agree to immediately report the defect or condition to Flamingo.</p>
                </li>
                <li className="fm-terms-list-item">
                  <p className="fm-terms-list-text">You must report any accident, crash, damage, malfunction, personal injury, stolen or lost Scooter or Bike to Flamingo as soon as possible.</p>
                </li>
                <li className="fm-terms-list-item">
                  <p className="fm-terms-list-text">By choosing to operate a Scooter or a Bike, you assume all responsibility and risk for all medical conditions that may interfere with your safe operation of a Scooter or a Bike and and/or all risk of injuries to yourself or others.</p>
                </li>
                <li className="fm-terms-list-item">
                  <p className="fm-terms-list-text">You agree as a condition of accessing the Scooters and/or the Bikes and the Service to confirm that you have viewed and agree to all of the Flamingo training materials, including the training cards and other portions of the Service.</p>
                </li>
                <li className="fm-terms-list-item">
                  <p className="fm-terms-list-text">Subject to clause 4.4 above regarding the limited use of Scooters or Bikes by minors and clause 10 below regarding group rides, you are the only person authorised to ride a Scooter or a Bike that is hired through your Account. You understand and acknowledge that you alone are responsible for complying with these Terms and that you alone are responsible for any damage resulting from the use of any Scooter or Bike that is rented through your Account and/or the breach of any provision of these Terms.</p>
                </li>
                <li className="fm-terms-list-item">
                  <p className="fm-terms-list-text">You represent and warrant that you are familiar with all laws applicable to operating a Scooter and/or a Bike, including helmet, traffic laws and other laws, and that you will fully comply with those laws while you are operating, riding, parking and/or charging a Scooter and/or a Bike.</p>
                </li>
                <li className="fm-terms-list-item">
                  <p className="fm-terms-list-text">When operating a Scooter or a Bike, you must wear an approved bicycle helmet that has been properly sized, fitted and fastened according to the manufacturer’s instructions at all times.</p>
                </li>
                <li className="fm-terms-list-item">
                  <p className="fm-terms-list-text">Only one (1) person may operate a Scooter or a Bike at a time and you must not carry passengers.</p>
                </li>
                <li className="fm-terms-list-item">
                  <p className="fm-terms-list-text">Scooters and Bikes shall not be used as follows:</p>
                  <ol className="fm-terms-list">
                    <li className="fm-terms-list-item">
                      <p className="fm-terms-list-text">While under the influence of drugs or alcohol;</p>
                    </li>
                    <li className="fm-terms-list-item">
                      <p className="fm-terms-list-text">While texting, emailing, or otherwise using a mobile device;</p>
                    </li>
                    <li className="fm-terms-list-item">
                      <p className="fm-terms-list-text">In a negligent or abusive manner or for any use outside the scope of a Scooter’s or Bike's intended purpose;</p>
                    </li>
                    <li className="fm-terms-list-item">
                      <p className="fm-terms-list-text">In connection with a criminal act;</p>
                    </li>
                    <li className="fm-terms-list-item">
                      <p className="fm-terms-list-text">With the intent to destroy public or private property or to cause injury or damage to persons or to public or private property;</p>
                    </li>
                    <li className="fm-terms-list-item">
                      <p className="fm-terms-list-text">While placing any bags or other tangible things on the handlebars which interferes with the proper and safe operation;</p>
                    </li>
                    <li className="fm-terms-list-item">
                      <p className="fm-terms-list-text">While being towed by another vehicle or holding on to the back of another vehicle;</p>
                    </li>
                    <li className="fm-terms-list-item">
                      <p className="fm-terms-list-text">During a race, competition or to perform tricks;</p>
                    </li>
                    <li className="fm-terms-list-item">
                      <p className="fm-terms-list-text">On unpaved roads, through water (beyond normal urban riding), or in any location that is prohibited, illegal and/or a nuisance to others;</p>
                    </li>
                    <li className="fm-terms-list-item">
                      <p className="fm-terms-list-text">For commercial purposes, such as rideshare, food delivery or advertising services;</p>
                    </li>
                    <li className="fm-terms-list-item">
                      <p className="fm-terms-list-text">To propel or tow any vehicle, trailer, or other object;</p>
                    </li>
                    <li className="fm-terms-list-item">
                      <p className="fm-terms-list-text">To transport animals of any kind or nature, living or otherwise;</p>
                    </li>
                    <li className="fm-terms-list-item">
                      <p className="fm-terms-list-text">While there is inclement weather, including heavy rain, electrical storms or strong wind, which can make it more dangerous to operate a Scooter or Bike; or</p>
                    </li>
                    <li className="fm-terms-list-item">
                      <p className="fm-terms-list-text">By anyone who has provided Flamingo with false information in order to create or keep an Account.</p>
                    </li>
                  </ol>
                  <p className="fm-terms-list-text">The foregoing list is not intended to be exhaustive and any unreasonable or inappropriate use of a Scooter or Bike, as determined by Flamingo in its sole discretion, or any violation of law will be deemed to be a violation of this provision.</p>
                </li>
                <li className="fm-terms-list-item">
                  <p className="fm-terms-list-text">When riding a Scooter or Bike on a path, you must:</p>
                  <ol className="fm-terms-list">
                    <li className="fm-terms-list-item">
                      <p className="fm-terms-list-text">Keep left and give way to pedestrians;</p>
                    </li>
                    <li className="fm-terms-list-item">
                      <p className="fm-terms-list-text">Travel at a speed that allows you to stop safely to avoid colliding with a pedestrian;</p>
                    </li>
                    <li className="fm-terms-list-item">
                      <p className="fm-terms-list-text">Travel at a safe distance from a pedestrian so you can avoid a collision; and</p>
                    </li>
                    <li className="fm-terms-list-item">
                      <p className="fm-terms-list-text">Keep left of oncoming bicycles and other personal mobility devices.</p>
                    </li>
                  </ol>
                </li>
                <li className="fm-terms-list-item">
                  <p className="fm-terms-list-text">When permitted to ride on a road, you must:</p>
                  <ol className="fm-terms-list">
                    <li className="fm-terms-list-item">
                      <p className="fm-terms-list-text">Keep as far to the left side of the road as practicable;</p>
                    </li>
                    <li className="fm-terms-list-item">
                      <p className="fm-terms-list-text">Not cause a traffic hazard by moving into the path of a driver; and</p>
                    </li>
                    <li className="fm-terms-list-item">
                      <p className="fm-terms-list-text">Not unreasonably obstruct the path of any other road user.</p>
                    </li>
                  </ol>
                </li>
                <li className="fm-terms-list-item">
                  <p className="fm-terms-list-text">You are responsible for all costs and fines incurred, and any damages, related to the Scooter or Bike and any Flamingo Materials from the time you activate a Scooter or Bike until the Scooter or Bike is returned and secured.</p>
                </li>
                <li className="fm-terms-list-item">
                  <p className="fm-terms-list-text">You are responsible for any offences, including but not limited to traffic or other such offences howsoever caused through your use of the Services together with penalties for committing the offence including fines, late payment fees, costs and any processing fees. You agree to address such penalties and pay all such sums. Such offences must be reported to Flamingo as soon as reasonably possible, but in any event, in advance of the deadline to respond to the offence. If you fail to pay for any offences incurred, and Flamingo is liable to make payment you agree that Flamingo may charge your payment method on record in accordance with these Terms for all Loss Flamingo incurs through your failure to address an offence.</p>
                </li>
                <li className="fm-terms-list-item">
                  <p className="fm-terms-list-text">You expressly agree that you will only use the Scooters and/or Bikes in the service area specified in the App and that should you breach this provision, you could cause Flamingo Loss. Flamingo reserves the right in Flamingo’s sole and absolute discretion to charge you for Loss incurred to remedy breach of this provision, currently estimated to be up to fifty New Zealand dollars (NZD$50) but not limited to that amount in Flamingo’s sole and absolute discretion. Without limiting any of Flamingo’s rights and remedies, repeated breach of this provision may lead Flamingo to disable a Scooter and/or Bike you have hired and to disable, terminate or suspend your Account without notice, </p>
                </li>
                <li className="fm-terms-list-item">
                  <p className="fm-terms-list-text">The maximum hire time of a Scooter or Bike is twenty four (24) hours. You agree that you will deactivate the Scooter or Bike rental within twenty four (24) hours after that hire began. Scooters and Bikes not returned within forty eight (48) hours will be considered lost or stolen, and a police report may be filed against you. We reserve the right to take any other action needed and permitted by law to recover Loss.</p>
                </li>
                <li className="fm-terms-list-item">
                  <p className="fm-terms-list-text">You agree to return the Scooter or Bike and any Flamingo Materials in the same condition in which such Scooter or Bike and any Flamingo Materials were hired, normal wear and tear excluded. If you fail to return any Flamingo Materials, for example, a courtesy helmet, you may be liable for the replacement of the Flamingo Materials in Flamingo’s sole and absolute discretion. Flamingo reserves the right to charge you for this Loss via the imposition of a specified fine identified in the App. </p>
                </li>
                <li className="fm-terms-list-item">
                  <p className="fm-terms-list-text">You agree that if you vandalise, damage, lose or otherwise make any Scooter, Bike or Flamingo Materials unusable, you shall be liable for all Loss in Flamingo’s sole and absolute discretion.</p>
                </li>
                <li className="fm-terms-list-item">
                  <p className="fm-terms-list-text">You must ensure the Scooter or Bike and any Flamingo Materials are in a clean and good working condition after your trip.</p>
                </li>
                <li className="fm-terms-list-item">
                  <p className="fm-terms-list-text">After your trip, you must leave the Scooter or Bike in a safe and responsible way having regard for other path and road users. You expressly agree that you will not park a Scooter or Bike in an unsafe or irresponsible manner, for example, blocking a path, entrance or emergency equipment and/or on the roadway, private property or in a car park, and that should you breach this provision, you could cause Flamingo Loss. Flamingo reserves the right in Flamingo’s sole and absolute discretion to charge you for Loss incurred to remedy breach of this provision via the imposition of a fine, currently estimated to be up to fifty New Zealand dollars (NZD$50) but not limited to that amount in Flamingo’s sole and absolute discretion. In certain scenarios, for example, when a Scooter or Bike is left directly on the edge of a wharf, you may be liable for any and all Loss in Flamingo’s sole and absolute discretion, including but not limited to the damage that occurs until the next booking and the cost of repairing or replacing the Scooter or Bike. Without limiting any of Flamingo’s rights and remedies, repeated breach of this provision may lead Flamingo to disable a Scooter or Bike you have hired and to disable, terminate or suspend your Account without notice.</p>
                </li>
                <li className="fm-terms-list-item">
                  <p className="fm-terms-list-text">To end your booking, the App will require you to take a photo of the parked Scooter or Bike. You expressly agree that you will take a clear photo that clearly shows the whole Scooter or Bike and it’s surroundings, and that should you breach this provision, you could cause Flamingo Loss. Flamingo reserves the right in Flamingo’s sole and absolute discretion to charge you for Loss incurred to remedy breach of this provision via the imposition of a fine, currently estimated to be up to fifty New Zealand dollars (NZD$50) but not limited to that amount in Flamingo’s sole and absolute discretion. In certain scenarios, for example, when a Scooter or Bike is lost, stolen or vandalised following your failure to take a clear photo, you may be liable for any and all Loss in Flamingo’s sole and absolute discretion, including but not limited to the damage that occurs until the next booking and the cost of repairing or replacing the Scooter or Bike. Without limiting any of Flamingo’s rights and remedies, repeated breach of this provision may lead Flamingo to disable a Scooter or Bike you have hired and to disable, terminate or suspend your Account without notice.</p>
                </li>
                <li className="fm-terms-list-item">
                  <p className="fm-terms-list-text">You must not park a Scooter or Bike in any area that is prohibited by law.</p>
                </li>
                <li className="fm-terms-list-item">
                  <p className="fm-terms-list-text">You must not take a Scooter or Bike out of public areas, for example, taking a Scooter or Bike into a building or other structure without our express permission. You must not place or park a Scooter or Bike in a manner that prevents Flamingo from accessing it. Flamingo reserves the right to charge you for Loss incurred to remedy breach of this provision, currently estimated to be up to four hundred and fifty New Zealand dollars (NZD$450) but not limited to that amount in Flamingo’s sole and absolute discretion.</p>
                </li>
                <li className="fm-terms-list-item">
                  <p className="fm-terms-list-text">You expressly agree that you will not park a Scooter or Bike in any area that is designated as a No Parking Zone in the App and that should you breach this provision, you could cause Flamingo Loss. Flamingo reserves the right in Flamingo’s sole and absolute discretion to charge you for Loss incurred to remedy breach of this provision via the imposition of a fine, currently estimated to be up to fifty New Zealand dollars (NZD$50) but not limited to that amount in Flamingo’s sole and absolute discretion. Without limiting any of Flamingo’s rights and remedies, repeated breach of this provision may lead Flamingo to disable a Scooter or Bike you have hired and to disable, terminate or suspend your Account without notice.</p>
                </li>
                <li className="fm-terms-list-item">
                  <p className="fm-terms-list-text">You must not use any lock or other tethering device on a Scooter or Bike.</p>
                </li>
                <li className="fm-terms-list-item">
                  <p className="fm-terms-list-text">You must not interfere or attempt to interfere, either directly or indirectly, with a Scooter or Bike’s GPS, wireless or other technology.</p>
                </li>
                <li className="fm-terms-list-item">
                  <p className="fm-terms-list-text">You must not remove or attempt to remove any Flamingo system designed to track the Scooter or Bike’s location or ride duration.</p>
                </li>
                <li className="fm-terms-list-item">
                  <p className="fm-terms-list-text">You must not tamper with, modify, repair, apply any form of decal or sticker to, or deface any Scooter or Bike (or any part thereof) in any way.</p>
                </li>
              </ol>
            </li>
            <li className="fm-terms-list-item fm-terms-list-item-top">
              <p className="fm-terms-list-header">Fees</p>
              <ol className="fm-terms-list">
                <li className="fm-terms-list-item">
                  <p className="fm-terms-list-text">You agree to pay to Flamingo the fees set forth on the Website and App for the time you use the Scooter or Bike. Flamingo may alter, amend or make changes to the fees set forth on the Website and App at any time and will provide notice of such changes on the Website and App.</p>
                </li>
                <li className="fm-terms-list-item">
                  <p className="fm-terms-list-text">Flamingo reserves the right to recover Loss including via the imposition of specified fees or fines identified in the Terms and/or on the Website or App for non-observance of any provision in these Terms.</p>
                </li>
                <li className="fm-terms-list-item">
                  <p className="fm-terms-list-text">You agree that Flamingo may charge your selected payment method for any such payments or monies howsoever owing to Flamingo. Flamingo accepts payments through the payment methods detailed on the applicable payment screen in the App. You may be asked to provide Flamingo with a credit card number from a card issuer that Flamingo accepts in order to activate your Account and/or pay for any fees related to the Service.</p>
                </li>
                <li className="fm-terms-list-item">
                  <p className="fm-terms-list-text">If you are directed to our third party payment processor(s), you may be subject to terms and conditions governing use of that third party’s service and that third party’s personal information collection practices. Please review such terms and conditions and privacy policy before using the services. Flamingo is not responsible for or liable for availability, accuracy, reliability or anything related to the third party’s service, including the content thereon. Links or other access to information of third party service providers shall not constitute an endorsement by Flamingo to such providers or their services.</p>
                </li>
                <li className="fm-terms-list-item">
                  <p className="fm-terms-list-text">Our payment processing services are currently provided by Stripe, and where applicable, may include money transmission services pursuant to licenses or other regulatory approvals held by Stripe. Payment processing services provided by Stripe are subject to the <a href="https://stripe.com/us/connect-account/legal">Stripe Connected Account Agreement</a>, which includes the <a href="https://stripe.com/us/legal">Stripe Services Agreement</a>. By agreeing to these Terms or by using the Flamingo Service or your Flamingo Account, you agree to be bound by the Stripe Connected Account Agreement and the Stripe Services Agreement, as they may be amended or modified from time-to-time by Stripe. Flamingo’s provision of any payment processing services through Stripe is subject to, and conditioned upon, your agreement to provide Flamingo up-to-date, accurate, and complete information relating to you and your payment instrument(s) and you expressly authorise Flamingo to share this information and transaction-related information relating to your use of payment related services with Stripe. Flamingo reserves the right to provide payment processing services through other third party vendors, and if it choose to do so, will provide notice to you via the Website, App, email, text message and/or through another method.</p>
                </li>
                <li className="fm-terms-list-item">
                  <p className="fm-terms-list-text">You agree that Flamingo is authorised to charge your selected payment method for any payments and any other sum that you owe to Flamingo as a result of your use of a Scooter or Bike which includes but is not limited to recovering Loss.</p>
                </li>
                <li className="fm-terms-list-item">
                  <p className="fm-terms-list-text">In addition to the amount due, if your account is in debit for any reason, you may be charged fees that are incidental to our collection of these debit amounts. Such fees or charges may include collection fees, convenience fees, interest or other third party charges.</p>
                </li>
                <li className="fm-terms-list-item">
                  <p className="fm-terms-list-text">Any credit card which is rejected may result in suspension or termination of your Account solely at Flamingo’s discretion. The user must notify Flamingo in the event of the credit card on record being changed, expiring, or being no longer valid and replace it with a valid credit card.</p>
                </li>
                <li className="fm-terms-list-item">
                  <p className="fm-terms-list-text">If your selected payment method is reported by the cardholder or bank as stolen or fraudulent for any reason, you must make payment to Flamingo of the total amount reported in addition to a network processing fee of twenty five New Zealand dollars (NZD$25) per reported transaction within seven (7) days of Flamingo receiving such report.</p>
                </li>
                <li className="fm-terms-list-item">
                  <p className="fm-terms-list-text">All fees are final and nonrefundable.</p>
                </li>
                <li className="fm-terms-list-item">
                  <p className="fm-terms-list-text">If for any reason you wish to Dispute any fee, you must notify us as soon as possible by contacting us on: hello@rideflamingo.com. You agree to submit any Disputes regarding any charge in writing to Flamingo within thirty (30) days of such charge, otherwise such Dispute will be waived and such charge will be final and not subject to challenge.</p>
                </li>
                <li className="fm-terms-list-item">
                  <p className="fm-terms-list-text">You understand and agree that we reserve the right, in our sole discretion, to charge your credit card a nominal amount to verify your credit card. We may also charge the fees we incur for accepting payment by credit card.</p>
                </li>
                <li className="fm-terms-list-item">
                  <p className="fm-terms-list-text">You understand and agree that we reserve the right, in our sole discretion, to place an authorisation hold on your credit card at the time you activate a Scooter or Bike, pending finalisation of the applicable fees once the Scooter or Bike is returned and secured. If we exercise this right, you understand that a Scooter and/or Bike will not be activated unless the authorisation hold has been approved.</p>
                </li>
                <li className="fm-terms-list-item">
                  <p className="fm-terms-list-text">Promo codes or discounts are one-time offers and can only be redeemed via the App. Flamingo reserves the right to modify or cancel promo codes or discounts at any time. Promo codes or discounts are limited to one (1) per Flamingo user and Account and may not be combined with other offers. Promo codes and discounts are non-transferable and may not be resold.  If Promo codes, discounts or pre-paid vouchers are not claimed within 12 months of issues they are not redeemable.</p>
                </li>
                <li className="fm-terms-list-item">
                  <p className="fm-terms-list-text">Please note that we cannot control any fees that may be charged to a user by his or her bank or credit card company related to our collection of payments and Flamingo disclaims all liability in this regard.</p>
                </li>
                <li className="fm-terms-list-item">
                  <p className="fm-terms-list-text">Flamingo may seek third party assistance to recover any monies owing to Flamingo by you.</p>
                </li>
                <li className="fm-terms-list-item">
                  <p className="fm-terms-list-text">You have no right of set off against or right to make deduction from any monies owing to Flamingo for any claimed, perceived or actual Loss you have or might have suffered.</p>
                </li>
                <li className="fm-terms-list-item">
                  <p className="fm-terms-list-text">You agree that if you do not pay Flamingo any monies owing to it you will pay:</p>
                  <ol className="fm-terms-list">
                    <li className="fm-terms-list-item">
                      <p className="fm-terms-list-text">Loss suffered by Flamingo;</p>
                    </li>
                    <li className="fm-terms-list-item">
                      <p className="fm-terms-list-text">Interest from the due date for payment until payment is made, payments going first to Interest and then principal;</p>
                    </li>
                    <li className="fm-terms-list-item">
                      <p className="fm-terms-list-text">All costs including legal costs on an indemnity basis incurred by third parties to recover monies owing by you to Flamingo; and</p>
                    </li>
                    <li className="fm-terms-list-item">
                      <p className="fm-terms-list-text">Interest on costs.</p>
                    </li>
                  </ol>
                </li>
              </ol>
            </li>
            <li className="fm-terms-list-item fm-terms-list-item-top">
              <p className="fm-terms-list-header">Intellectual Property</p>
              <ol className="fm-terms-list">
                <li className="fm-terms-list-item">
                  <p className="fm-terms-list-text">We (and our licensors) have the right to all proprietary and Intellectual Property rights in the Service, Underlying System, the App, Website and Flamingo Software.</p>
                </li>
                <li className="fm-terms-list-item">
                  <p className="fm-terms-list-text">You are prohibited from attempting to extract the source code of the Service, Underlying System, the App, Website and Flamingo Software and you also must not attempt to translate the Service, Underlying System, the App, Website and Flamingo Software into other languages, or make derivative versions.</p>
                </li>
                <li className="fm-terms-list-item">
                  <p className="fm-terms-list-text">You will not infringe any Intellectual Property rights we have in the Service, Underlying System, the App, Website and Flamingo Software.</p>
                </li>
                <li className="fm-terms-list-item">
                  <p className="fm-terms-list-text">You will keep Confidential Information of Flamingo that comes to your notice, into your possession or control confidential and in the event you are in receipt of material that you think might be Confidential Information of Flamingo, you will contact Flamingo at Flamingo of legal@rideflamingo.com. immediately to advise Flamingo of the material and/or Confidential Information in your possession.</p>
                </li>
                <li className="fm-terms-list-item">
                  <p className="fm-terms-list-text">While you indemnify us for any Loss we incur as a result of your breach of this clause 7, you accept that damages will not be a sufficient remedy and agree that in the event of any breach of our Intellectual Property rights, we are entitled to an interim injunction to restrain ongoing breach of our Intellectual Property rights.</p>
                </li>
              </ol>
            </li>
            <li className="fm-terms-list-item fm-terms-list-item-top">
              <p className="fm-terms-list-header">Assumption of Risk, Releases, Warranties and Disclaimers</p>
              <ol className="fm-terms-list">
                <li className="fm-terms-list-item">
                  <p className="fm-terms-list-text">You agree that Flamingo’s sole responsibilities is to provide the Services within the area permitted by the Local Authority, Regional Authority and Government for Scooter and Bike use and in doing so you acknowledge Flamingo does not guarantee or otherwise warrant a Scooter or Bike will always be available at a particular time or place.  Flamingo is not in control of the location of Scooters and Bikes.  You further acknowledge and agree that:</p>
                  <ol className="fm-terms-list">
                    <li className="fm-terms-list-item">
                      <p className="fm-terms-list-text">The use of the Service (including the use of the Scooters and/or Bikes) is an inherently dangerous activity that involves both obvious and non-obvious risks of physical harm, including the risk of death to you and others, as well as damage to property;</p>
                    </li>
                    <li className="fm-terms-list-item">
                      <p className="fm-terms-list-text">These types of risks can be unforeseeable and sometimes cannot be avoided;</p>
                    </li>
                    <li className="fm-terms-list-item">
                      <p className="fm-terms-list-text">You understand such risks and accept sole and full responsibility for such risks;</p>
                    </li>
                    <li className="fm-terms-list-item">
                      <p className="fm-terms-list-text">You are solely responsible and knowingly assume such risk for any and all injury or damage caused by your use of the Service, a Scooter, a Bike and/or any related equipment that is a part of, relate to or concern your use of a Scooter or Bike, regardless of whether the injury or damage is caused to you or to others; and</p>
                    </li>
                    <li className="fm-terms-list-item">
                      <p className="fm-terms-list-text">You assume full and complete responsibility for your use of a Scooter, a Bike or the Service and agree that Flamingo is not responsible for any Loss that you cause that is related to or arises out of your use of a Scooter, a Bike, the Service and/or any equipment or services that are a part of, relate to or concern your use of a Scooter, a Bike or the Service including damage to the Scooter or the Bike itself.</p>
                    </li>
                  </ol>
                </li>
                <li className="fm-terms-list-item">
                  <p className="fm-terms-list-text">To the extent permitted by law, Flamingo provides the Service (including use of the Scooters, the Bikes and any other Flamingo Materials) to you on the condition that you (and anyone claiming on your behalf) releases, discharges, and covenants not to sue Flamingo and its affiliates, successors, officers, employees, representatives, partners, agents, and anyone claiming through them or on their behalf (collectively, the “Released Parties”) from any and all claims, liabilities, obligations, promises, agreements, Disputes, demands, damages, Losses, causes of action of any nature and kind, known or unknown, suspected or unsuspected which you have or ever had or may in the future have against Flamingo or any of the Released Parties (“Claims”) arising out of or in connection with any injury, illness, death, harm or Loss which you (and anyone claiming on your behalf) may suffer as a result of your use of the Service. You acknowledge that you enter into this release freely, knowingly, and voluntarily, and that you intend it to be a complete and unconditional release and waiver of all claims arising out of or in any way related to your use of the Service. You acknowledge and agree that it is your intention to release all of the Claims that you may have as described above, except as those claims that cannot lawfully be released.</p>
                </li>
                <li className="fm-terms-list-item">
                  <p className="fm-terms-list-text">To the extent permitted by law Flamingo and the Released Parties disclaim all express and implied warranties, including but not limited to the implied warranty of merchantability and the implied warranty of fitness for a particular purpose. The Service, Website, App and anything related to your use of a Scooter or a Bike is provided as-is and as available and you rely on it solely at your own risk.</p>
                </li>
                <li className="fm-terms-list-item">
                  <p className="fm-terms-list-text">Flamingo and the Released Parties make no representation or warranty that the Service (including the Scooters, the Bikes and other Flamingo Materials) will be in good repair or error-free or free from delays, defects, omissions, interruptions or inaccuracies.</p>
                </li>
                <li className="fm-terms-list-item">
                  <p className="fm-terms-list-text">You understand and agree to the following:</p>
                  <ol className="fm-terms-list">
                    <li className="fm-terms-list-item">
                      <p className="fm-terms-list-text">The level of charge power remaining in the Scooter or the Bike will decrease with use of the Scooter or Bike (over both time and distance), and that as the level of charge power decreases, the speed and other operational capabilities of the Scooter or Bike may decrease (or cease in their entirety);</p>
                    </li>
                    <li className="fm-terms-list-item">
                      <p className="fm-terms-list-text">The level of charging power in the Scooter or Bike at the time you initiate the rental or operation of the Scooter or Bike is not guaranteed and will vary with each rental use;</p>
                    </li>
                    <li className="fm-terms-list-item">
                      <p className="fm-terms-list-text">The rate of loss of charging power during the use of the Scooter or Bike is not guaranteed and will vary based on the Scooter or Bike, road conditions, weather conditions and/or other factors;</p>
                    </li>
                    <li className="fm-terms-list-item">
                      <p className="fm-terms-list-text">It is your responsibility to check the level of charge power in the Scooter or the Bike and to ensure that it is adequate for the ride before initiating operation of the Scooter or the Bike; and</p>
                    </li>
                    <li className="fm-terms-list-item">
                      <p className="fm-terms-list-text">Flamingo does not guarantee the distance and/or time that you may operate any Scooter or Bike before it loses charging power completely. The Scooter or Bike may run out of charging power and cease to operate at any time during your hire of the Scooter or the Bike, including before reaching your desired destination.</p>
                    </li>
                  </ol>
                </li>
                <li className="fm-terms-list-item">
                  <p className="fm-terms-list-text">To the extent permitted by law, we have no liability or responsibility to you or any other person for any Loss arising out of or in connection with:</p>
                  <ol className="fm-terms-list">
                    <li className="fm-terms-list-item">
                      <p className="fm-terms-list-text">The Service being unavailable (in whole or in part) or performing slowly. Flamingo does not guarantee the availability or functionality of a Scooter, a Bike or any Flamingo Materials for use by you, whether or not reserved. Use of a Scooter, a Bike or any Flamingo Materials is subject to availability at a given location on a first-come, first-served basis, and due to the nature of the Service, Flamingo can not guarantee that availability of Scooters and Bikes will be adequate to provide a Scooter or Bike to you at any given time;</p>
                    </li>
                    <li className="fm-terms-list-item">
                      <p className="fm-terms-list-text">Any error in, or omission from, any information made available through the Service, App or Website;</p>
                    </li>
                    <li className="fm-terms-list-item">
                      <p className="fm-terms-list-text">Any exposure to viruses or other forms of interference which may damage your computer system or mobile device or expose you to fraud when you access or use the Service, App and/or Website. To avoid doubt, you are responsible for ensuring the process by which you access and use the Service, App and/or Website protects you from this;</p>
                    </li>
                    <li className="fm-terms-list-item">
                      <p className="fm-terms-list-text">Any site linked from the Service, App or Website. Any link on the Service, App or Website to other sites does not imply any endorsement, approval or recommendation of, or responsibility for, those sites or their contents, operations, products or operators;</p>
                    </li>
                    <li className="fm-terms-list-item">
                      <p className="fm-terms-list-text">Any goods in or on the Scooter or Bike, including your mobile device that is running the App; or</p>
                    </li>
                    <li className="fm-terms-list-item">
                      <p className="fm-terms-list-text">Any delay or failure in performance to the extent the delay or failure is caused by events beyond our reasonable control, including, a significant failure of the Internet or telecommunications network, inclement weather, fire, flood, acts of God, explosion, war or the engagement of hostilities, strike, embargo, labour dispute, government or local authority requirement, civil disturbances, or civil or military authority.</p>
                    </li>
                  </ol>
                </li>
                <li className="fm-terms-list-item">
                  <p className="fm-terms-list-text">We make no representation or warranty that the Service is appropriate or available for use in all countries or regions or that the content satisfies the laws of all countries and regions. You are responsible for ensuring that your access to and use of the Service is not illegal or prohibited, and for your own compliance with applicable local laws.</p>
                </li>
                <li className="fm-terms-list-item">
                  <p className="fm-terms-list-text">Users are not employees of, or contractors to, Flamingo. Users do not provide any services to us, and nothing in these Terms or the Service will constitute any trust, joint venture, partnership, agency, arrangement or fiduciary obligation between any user and Flamingo. You acknowledge and agree that Flamingo does not control, and has no right to control, you or other third parties with whom you may come into contact while using the Service, including but not limited to pedestrians, cyclists, other Scooter or Bike riders, or other vehicles.</p>
                </li>
                <li className="fm-terms-list-item">
                  <p className="fm-terms-list-text">These Terms, and any rights and licenses granted hereunder, may not be transferred or assigned by you, but may be assigned or otherwise transferred by Flamingo without restriction. Any assignment attempted to be made by you in violation of this clause shall be void. These Terms will be binding upon and inure to the benefit of the parties and permitted successors and assigns. No delay or omission by Flamingo to exercise any right or power occurring upon any noncompliance or default by you with respect to any of the clauses of these Terms shall impair any right or power to be construed to be a waiver thereof. A waiver by Flamingo of any of the covenants, clauses, conditions, or agreements to be performed by you shall not be construed to be a waiver of any succeeding breach thereof of any covenant, clause, condition, or agreement herein contained. Unless otherwise stated, all remedies provided for in these Terms shall be cumulative and in addition to and not in lieu of any other remedies available to each party at law, in equity, or otherwise. Flamingo may freely assign or otherwise transfer these Terms and all of the policies and other documents incorporated or referenced in it (including all rights, licenses, and obligations under it or them), in whole or in part and without notice.</p>
                </li>
              </ol>
            </li>
            <li className="fm-terms-list-item fm-terms-list-item-top">
              <p className="fm-terms-list-header">Limited Liability</p>
              <ol className="fm-terms-list">
                <li className="fm-terms-list-item">
                  <p className="fm-terms-list-text">To the maximum extent permitted by law:</p>
                  <ol className="fm-terms-list">
                    <li className="fm-terms-list-item">
                      <p className="fm-terms-list-text">You access and use the Service (including the Scooters and Bikes) at your own risk;</p>
                    </li>
                    <li className="fm-terms-list-item">
                      <p className="fm-terms-list-text">We are not liable or responsible to you or any other person for any Loss under or in connection with these Terms, the Service, or your access and use of (or inability to access or use) the Service; and</p>
                    </li>
                    <li className="fm-terns-list-item">
                      <p className="fm-terms-list-text"> This exclusion applies regardless of whether our liability or responsibility arises in contract, tort (including negligence), equity, breach of statutory duty, or otherwise.</p>
                    </li>
                  </ol>
                </li>
                <li className="fm-terms-list-item">
                  <p className="fm-terms-list-text">You acknowledge and agree that, to the maximum extent permitted by law, the entire risk arising out of your access to and use of the Service or anything that relates to or concerns your use of a Scooter or the Service, remains with you. Neither Flamingo nor any other party involved in creating, producing, or delivering the Service, including the Scooters, will be liable for any incidental, special, punitive, exemplary or consequential damages, including lost profits, loss of data or loss of goodwill, service interruption, or the cost of substitute products or services, or for any damages for personal or bodily injury or emotional distress arising out of or in connection with:</p>
                  <ol ClassName="fm-terms-list">
                    <li className="fm-terms-list-item">
                      <p className="fm-terms-list-text">These Terms;</p>
                    </li>
                    <li className="fm-terms-list-item">
                      <p className="fm-terms-list-text">The use of or inability to use the Service and/or anything that relates to or concerns your use of a Scooter or Bike;</p>
                    </li>
                    <li className="fm-terms-list-item">
                      <p className="fm-terms-list-text">Any communications, interactions or meetings with other users or other persons with whom you communicate, interact or meet with as a result of your use of the Service;</p>
                    </li>
                    <li className="fm-terms-list-item">
                      <p className="fm-terms-list-text">Any and all uses of the Service including the Website, App, Service (including the Scooters and Bikes);</p>
                    </li>
                    <li className="fm-terms-list-item">
                      <p className="fm-terms-list-text">Any Local Authority, Regional Authority and/or Government requirements in connection with provision of the Services or withdrawal of permission to provide the Services; and/or</p>
                    </li>
                    <li className="fm-terms-list-item">
                      <p className="fm-terms-list-text">Your misconduct or negligent use of the Service including the Scooters and the Bikes, or the negligence or misconduct of a third party whether based on warranty, contract, tort (including negligence), product liability or any other legal or equitable basis, and whether or not Flamingo has been informed of the possibility of such damage or liability.</p>
                    </li>
                  </ol>
                </li>
                <li className="fm-terms-list-item">
                  <p className="fm-terms-list-text">To the maximum extent permitted by law and only to the extent clauses 9.1 and 9.2 of these Terms do not apply, our aggregate liability arising out of, relating to your relationship with Flamingo, these Terms, your use of the Service or anything arising out of or in connection with your use of the Services, a Scooter and/or a Bike, will not exceed the amounts you have paid to Flamingo in the twelve (12) month period prior to the event giving rise to the liability, or one hundred New Zealand dollars (NZD$100), if no such payment has been made. The limitations of damages set forth above are essential to Flamingo’s willingness to provide the Service to you and to permit your use of Scooters and Bikes.</p>
                </li>
                <li className="fm-terms-list-item">
                  <p className="fm-terms-list-text">To the extent permitted by law you agree to defend, indemnify, and hold harmless the Released Persons from and against any and all consequences, claims, demands, causes of action, Losses, liabilities, damages, injuries, fees, costs and expenses, penalties, attorneys’ fees, judgments, suits settlements, and/or disbursements of any kind, or nature whatsoever, whether foreseeable or unforeseeable, and whether known or unknown, that directly or indirectly arise from or are related to any claim, suit, action, demand, or proceeding made or brought against any Released Person, or on account of the investigation, defense, or settlement thereof, arising out of or in any way relating to any injury, illness, death or Loss allegedly suffered as a result of your use of the Service including the Scooters and/or Bikes or anything that relates to or concerns your use of the Services including the Scooters and Bikes. </p>
                </li>
                <li className="fm-terms-list-item">
                  <p className="fm-terms-list-text">To the extent permitted by law you indemnify us against all Loss we suffer or incur arising out of or in connection with your use of the Services and/or your failure to comply with these Terms.</p>
                </li>
                <li className="fm-terms-list-item">
                  <p className="fm-terms-list-text">You are required to sign a separate “Release of Liability” as a condition of creating a Flamingo Account and using the Service. The Release of Liability is incorporated into these Terms by this reference as if it were set forth fully herein.</p>
                </li>
              </ol>
            </li>
            <li className="fm-terms-list-item fm-terms-list-item-top">
              <p className="fm-terms-list-header">Group Rides</p>
              <ol className="fm-terms-list">
                <li className="fm-terms-list-item">
                  <p className="fm-terms-list-text">If you initiate a group ride by activating more than one Scooter or Bike using the App (a “Group Ride”), you:</p>
                  <ol className="fm-terms-list">
                    <li className="fm-terms-list-item">
                      <p className="fm-terms-list-text">Agree that you act as an agent for each other rider on the Group Ride (each a “Group Rider”) in acceptance of these Terms and our Privacy Policy, found at https://rideflamingo.com/privacy, for the purpose of their use of a Scooter or a Bike and/or any of the Service on the Group Ride;</p>
                    </li>
                    <li className="fm-terms-list-item">
                      <p className="fm-terms-list-text">Undertake and certify that, each Group Rider has read and agreed to these Terms and our Privacy Policy, found at https://rideflamingo.com/privacy.</p>
                    </li>
                    <li className="fm-terms-list-item">
                      <p className="fm-terms-list-text">You assume full financial and/or any other responsibility for any and all misuse, consequences, claims, demands, causes of action, Losses, liabilities, damages, injuries, fees, costs and expenses, penalties, attorneys’ fees, judgments, suits and/or disbursements of any kind, or nature whatsoever, whether foreseeable or unforeseeable, and whether known or unknown, as a result of the use of the Scooter, Bike and/or any of the Service; (whether relating to you or a Group Rider);</p>
                    </li>
                    <li className="fm-terms-list-item">
                      <p className="fm-terms-list-text">Accept and agree to pay all fees for the use of the Service by you and each Group Rider on the Group Ride and these will be charged to your account for payment under clause</p>
                    </li>
                    <li className="fm-terms-list-item">
                      <p className="fm-terms-list-text">Undertake and certify that all Group Riders are eligible to use the Service under clause 4.</p>
                    </li>
                  </ol>
                </li>
                <li className="fm-terms-list-item">
                  <p className="fm-terms-list-text">If you access the Service as a Group Rider on a Group Ride, you:</p>
                  <ol className="fm-terms-list">
                    <li className="fm-terms-list-item">
                      <p className="fm-terms-list-text">Irrevocably appoint the person activating the Scooter or Bike as your agent to accept on your behalf these Terms and our our Privacy Policy, found at https://rideflamingo.com/privacy, and in respect of your use of a Scooter, Bike and/or any of the Service for the Group Ride;</p>
                    </li>
                    <li className="fm-terms-list-item">
                      <p className="fm-terms-list-text">Confirm and acknowledge that, notwithstanding this agent appointment, you accept these Terms and our Privacy Policy, found at https://rideflamingo.com/privacy.</p>
                    </li>
                    <li className="fm-terms-list-item">
                      <p className="fm-terms-list-text">Accept and agree that you are liable for any Loss arising from or related to your use of a Scooter, Bike and/or any of the Service on the Group Ride.</p>
                    </li>
                  </ol>
                </li>
              </ol>
            </li>
            <li className="fm-terms-list-item fm-terms-list-item-top">
              <p className="fm-terms-list-header">Subscriptions</p>
              <ol className="fm-terms-list">
                <li className="fm-terms-list-item">
                  <p className="fm-terms-list-text">If available in your area, you may use our Service by purchasing a subscription.</p>
                </li>
                <li className="fm-terms-list-item">
                  <p className="fm-terms-list-text">Your subscription will begin on the date your first payment is received and continue for the period of time you selected at the time of purchase. We will charge your selected payment method at agreed intervals throughout the term of your subscription.</p>
                </li>
                <li className="fm-terms-list-item">
                  <p className="fm-terms-list-text">The applicable price is the price at the time you purchase your subscription (we reserve the right to change prices over time, and we do not provide price protection or refunds in the event of a price decrease or promotional offering).</p>
                </li>
                <li className="fm-terms-list-item">
                  <p className="fm-terms-list-text">We reserve the right, in our sole and absolute discretion, to modify, terminate or otherwise amend any subscription membership plans.</p>
                </li>
                <li className="fm-terms-list-item">
                  <p className="fm-terms-list-text">We may cancel your subscription for any or no reason with 30 days prior written notice. We may cancel your subscription immediately if you fail to pay amounts due, or if you are in material breach of these Terms.</p>
                </li>
                <li className="fm-terms-list-item">
                  <p className="fm-terms-list-text">You may cancel your subscription for any or no reason with 30 days prior written notice, however you shall not receive a partial refund and it may mean you forfeit any membership fee or sign-up fee.</p>
                </li>
                <li className="fm-terms-list-item">
                  <p className="fm-terms-list-text">Subscriptions will automatically renew at current prices for the same length of time as your original membership term. It will continue indefinitely unless you cancel it before the end of your current subscription term. Our pricing and applicable taxes and fees may change our time and the pricing from one subscription term to the next may be different. We reserve the right to change subscription pricing at any time and we will provide notice of such change in advance. If you do not agree with the new pricing, you can cancel your subscription.</p>
                </li>
                <li className="fm-terms-list-item">
                  <p className="fm-terms-list-text">If for any reason you wish to Dispute any fee, you must notify us as soon as possible by contacting us on: hello@rideflamingo.com. You agree to submit any Disputes regarding any charge in writing to Flamingo within thirty (30) days of such charge, otherwise such Dispute will be waived and such charge will be final and not subject to challenge.</p>
                </li>
              </ol>
            </li>

            <li className="fm-terms-list-item fm-terms-list-item-top">
              <p className="fm-terms-list-header">Privacy Policy</p>
              <ol className="fm-terms-list">
                <li className="fm-terms-list-item">
                  <p className="fm-terms-list-text">You agree that our Privacy Policy https://rideflamingo.com/privacy (as may be updated from time to time) governs our collection and use of your personal information.</p>
                </li>
              </ol>
            </li>
            <li className="fm-terms-list-item fm-terms-list-item-top">
              <p className="fm-terms-list-header">Suspension and Termination</p>
              <ol className="fm-terms-list">
                <li className="fm-terms-list-item">
                  <p className="fm-terms-list-text">Without prejudice to any other right or remedy available to us, if we consider that you have breached these Terms or we otherwise consider it appropriate, we may immediately, and without notice, suspend or terminate your access to the Service (or any part of it). If we exercise our discretion under these Terms to do so, any or all of the following can occur with or without prior notice or explanation to you:</p>
                  <ol className="fm-terms-list">
                    <li className="fm-terms-list-item">
                      <p className="fm-terms-list-text">All rights and licences granted to you in respect of the Service will cease, and you must immediately stop using the Service;</p>
                    </li>
                    <li className="fm-terms-list-item">
                      <p className="fm-terms-list-text">Your Account will be deactivated or suspended and you will not be able to access the Service or receive assistance from the Flamingo support teams; and</p>
                    </li>
                    <li className="fm-terms-list-item">
                      <p className="fm-terms-list-text">If appropriate in our sole discretion, we may communicate to other Flamingo users or third parties that your Account has been terminated, blocked, suspended, deactivated, or cancelled, and why this action has been taken.</p>
                    </li>
                  </ol>
                </li>
                <li className="fm-terms-list-item">
                  <p className="fm-terms-list-text">We may, at any time, without notice, immediately terminate your access to the Service if you are not paying your debts as such debts generally become due, become insolvent, file for bankruptcy, or are convicted of a driving-related criminal offence (e.g., such as driving under the influence of alcohol or controlled substances, hit-and-run, reckless driving).</p>
                </li>
                <li className="fm-terms-list-item">
                  <p className="fm-terms-list-text">You may cancel your Account at any time by emailing hello@rideflamingo.com. If you wish to restart your Account you will be required to set up a new Account in accordance with these Terms or the Terms then in effect.</p>
                </li>
                <li className="fm-terms-list-item">
                  <p className="fm-terms-list-text">On suspension or termination, you must immediately cease using the Service and must not attempt to gain further access.</p>
                </li>
                <li className="fm-terms-list-item">
                  <p className="fm-terms-list-text">You shall be responsible for any fees or costs incurred up to and including the date of termination.</p>
                </li>
                <li className="fm-terms-list-item">
                  <p className="fm-terms-list-text">In case of termination, you agree to return immediately to Flamingo any Scooter, Bike or any other Flamingo Materials you might have in your possession. You agree to pay any legal fees, court costs or costs of other legal procedures together with Interest and Interest on such costs necessary or desirable for Flamingo to recover any amounts due and owing, the Scooter, the Bike or any other Flamingo Materials you might have in your possession under these Terms.</p>
                </li>
              </ol>
            </li>
            <li className="fm-terms-list-item fm-terms-list-item-top">
              <p className="fm-terms-list-header">Dispute Resolution</p>
              <ol className="fm-terms-list">
                <li className="fm-terms-list-item">
                  <p className="fm-terms-list-text">Any dispute between you and Flamingo arising out of or in connection with these Terms, provision of the Services or use or availability of Scooters and/or Bikes (a <b>“relevant dispute”</b>) will be resolved in accordance with the following provisions:</p>
                  <ol className="fm-terms-list">
                    <li className="fm-terms-list-item">
                      <p className="fm-terms-list-text">A party which considers a relevant dispute has arisen must deliver a written notice setting out the matters in dispute (the <b>“dispute notice”</b>) to the other party and both parties will consult in good faith to resolve the relevant dispute for a period of 10 working days commencing on the date of receipt of the dispute notice by the other party.</p>
                    </li>
                    <li className="fm-terms-list-item">
                      <p className="fm-terms-list-text">If the parties do not resolve a relevant dispute within the 10 working day period, either party may refer the dispute to an independent lawyer appointed by agreement (or failing agreement within two (2) working days, by the President for the time being of the New Zealand Law Society) (the <b>“Independent Expert”</b>).</p>
                    </li>
                    <li className="fm-terms-list-item">
                      <p className="fm-terms-list-text">The Independent Expert will act as expert, not an arbitrator, and accordingly the Arbitration Act 1996 will not apply.  The Independent Expert will be instructed to determine all relevant disputes referred to them as soon as possible, and their determination will be conclusive and binding on the parties, and not open to challenge.  The parties shall provide the Independent Expert with all information the Independent Expert requests and such information shall be treated as Confidential Information by the parties. The Independent Expert’s costs will be shared equally between the parties.  Subject to recovery of fees by Flamingo, enforcement of Flamingo’s intellectual property rights, urgent or interlocutory relief both parties agree that they have no ability to refer any relevant dispute, nor the procedure taken or process followed in respect of any relevant dispute, for consideration, decision or determination by any court, other tribunal or in any way whatsoever other than through the procedure set out in, or permitted by this dispute resolution process.</p>
                    </li>
                    <li className="fm-terns-list-item">
                      <p className="fm-terms-list-text">The parties agree that the determination of the Independent Expert will be enforceable against a party in the Court.</p>
                    </li>
                  </ol>
                </li>
                <li className="fm-terms-list-item">
                  <p className="fm-terms-list-text">You and Flamingo agree that all dispute resolution proceedings are to be Treated as Confidential Information and this extends to material provided as part of the dispute resolution process, arguments made and determinations given unless otherwise determined by the Independent Expert.</p>
                </li>
                <li className="fm-terms-list-item">
                  <p className="fm-terms-list-text">The provisions contained in this Section 14 will survive the termination of these Terms and will continue to apply even if you subsequently stop using the Service and/or your Flamingo Account is terminated.</p>
                </li>
              </ol>
            </li>
            <li className="fm-terms-list-item fm-terms-list-item-top">
              <p className="fm-terms-list-header">Feedback</p>
              <ol className="fm-terms-list">
                <li className="fm-terms-list-item">
                  <p className="fm-terms-list-text">By sending Flamingo any Feedback you represent and warrant that:</p>
                  <ol className="fm-terms-list">
                    <li className="fm-terms-list-item">
                      <p className="fm-terms-list-text">You have the right to disclose the Feedback;</p>
                    </li>
                    <li className="fm-terms-list-item">
                      <p className="fm-terms-list-text">That the Feedback does not violate the rights of any other person or entity; and</p>
                    </li>
                    <li className="fm-terms-list-item">
                      <p className="fm-terms-list-text">That your Feedback does not contain the confidential or proprietary information of any third party or parties.</p>
                    </li>
                  </ol>
                </li>
                <li className="fm-terms-list-item">
                  <p className="fm-terms-list-text">By sending us any Feedback, you further:</p>
                  <ol className="fm-terms-list">
                    <li className="fm-terms-list-item">
                      <p className="fm-terms-list-text">Agree that we are under no obligation of confidentiality, express or implied, with respect to the Feedback;</p>
                    </li>
                    <li className="fm-terms-list-item">
                      <p className="fm-terms-list-text">Acknowledge that we may have something similar to the Feedback already under consideration or in development;</p>
                    </li>
                    <li className="fm-terms-list-item">
                      <p className="fm-terms-list-text">Grant us an irrevocable, non-exclusive, royalty-free, perpetual, worldwide license to use, modify, prepare derivative works, publish, distribute, sublicense or otherwise exploit including commercially the Feedback for all purposes in all jurisdictions; and</p>
                    </li>
                    <li className="fm-terms-list-item">
                      <p className="fm-terms-list-text">Irrevocably waive, and cause to be waived, against Flamingo and its users any claims and assertions of any moral rights contained in such Feedback.</p>
                    </li>
                  </ol>
                </li>
                <li className="fm-terms-list-item">
                  <p className="fm-terms-list-text">For the removal of doubt and without limitation the provisions contained in this Section 15 will survive the termination of these Terms and will continue to apply even if you subsequently stop using the Service and/or your Account is terminated.</p>
                </li>
              </ol>
            </li>
            <li className="fm-terms-list-item fm-terms-list-item-top">
              <p className="fm-terms-list-header">Notices</p>
              <ol className="fm-terms-list">
                <li className="fm-terms-list-item">
                  <p className="fm-terms-list-text">If we need to contact you, we may do so by email, text message, telephone or by posting a notice on the Website and/or App. You agree that this satisfies all legal requirements in relation to written communications.</p>
                </li>
                <li className="fm-terms-list-item">
                  <p className="fm-terms-list-text">By providing your email and phone number to Flamingo, you consent to receive emails and text messages from Flamingo relating to the provision of the Service. Flamingo may periodically send emails and text messages of an informational or advertising nature. Such uses include, without limitation, to provide you with information you requested from Flamingo, to let you know about features, functions, improvements or changes to the Service, or other aspects of your Account. These emails and/or text messages may be automated or non-automated, and while Flamingo does not charge you to send or receive such messages, other message and data rates may still apply by your carrier or other provider. You may choose to opt-out of receiving emails by selecting the unsubscribe link at the foot of every such email. You acknowledge and agree that notwithstanding your request to opt out from such messages, Flamingo may still send and you may still receive emails or text messages reasonably required for the proper conduct of the Service. If you do not wish to receive any messages from Flamingo, you must terminate your Account and cease using the Service.</p>
                </li>
                <li className="fm-terms-list-item">
                  <p className="fm-terms-list-text">Notwithstanding your determination to opt-out of receiving electronic messages or cease use of the Service, you agree that in the event an incident occurs in which a third party obtains unauthorised access to your personal data provided to Flamingo, you agree that should Flamingo become legally obligated to provide notice of such unauthorised access, then Flamingo may provide such notice to you electronically by using the email address or mobile telephone number provided by you.</p>
                </li>
              </ol>
            </li>
          </ol>
        </div>
      </div>
    );
  }
}

export default Terms;
