import React from 'react';

class PageHeading extends React.Component {
  render() {
    return (
      <div className="fm-page-heading" style={{ backgroundImage: 'url(' + this.props.image + ')'}}>
        <div className="fm-page-heading-overlay">
          <div className="fm-page-heading-content">
            <p className="fm-brand fm-page-heading-title">{ this.props.title }</p>
          </div>
        </div>
      </div>
    );
  }
}

export default PageHeading;
