import React, { Component } from 'react';
import WorkplaceSessionImage from './components/workplace-session-image';
import WorkplaceSessionBlurb from './components/workplace-session-blurb';
import WorkplaceSessionBenefits from './components/workplace-session-benefits';
import WorkplaceSessionForm from './components/workplace-session-form';

class WorkplaceSession extends Component {
  componentDidMount() {
    document.title = 'Workplace Sessions | Flamingo';
  }

  render() {
    return (
      <div className="fm-workplace-session">
        <WorkplaceSessionImage />
        <WorkplaceSessionBlurb />
        <WorkplaceSessionBenefits />
        <WorkplaceSessionForm />
      </div>
    );
  }
}

export default WorkplaceSession;
