import React, { Component } from 'react';
import Loading from '../common/loading';
import history from '../../helpers/history';

class Download extends Component {
  constructor() {
    super();
    this.handleDownload = this.handleDownload.bind(this);
    this.openIOS = this.openIOS.bind(this);
    this.openAndroid = this.openAndroid.bind(this);
  }

  componentDidMount() {
    document.title = 'Download | Flamingo';
    this.handleDownload();
  }

  handleDownload() {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    // Android
    if (/android/i.test(userAgent)) {
      return this.openAndroid();
    }
    // iOS
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return this.openIOS();
    }
    // Other
    return history.push('/ride');
  }

  openIOS() {
    window.location = 'com.flamingoscooters://';
    setTimeout(() => {
      window.location = 'itms-apps://itunes.apple.com/nz/app/flamingo-scooters/id1446388027';
      setTimeout(() => {
        window.location = 'https://itunes.apple.com/nz/app/flamingo-scooters/id1446388027';
      }, 3000);
    }, 3000);
  }

  openAndroid() {
    window.location = 'https://play.google.com/store/apps/details?id=com.flamingoscooters.android';
  }

  render() {
    return (
      <div className="fm-helmets">
        <Loading />
      </div>
    );
  }
}

export default Download;
