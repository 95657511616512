import React, { Component } from 'react';
import DiscountsImage from './components/discounts-image';
import DiscountsTypes from './components/discounts-types';
import DiscountsCalculator from './components/discounts-calculator';

class Discounts extends Component {
  componentDidMount() {
    document.title = 'Community & Student Discounts | Flamingo';
  }

  render() {
    return (
      <div className="fm-discounts">
        <DiscountsImage />
        <DiscountsTypes />
        <DiscountsCalculator />
      </div>
    );
  }
}

export default Discounts;
