import React, { Component } from 'react';

class PromoImage extends Component {
  render() {
    const { promo } = this.props;
    return (
      <div className="fm-promo-image">
        <div className="fm-promo-image-background">
          <div className="fm-promo-image-background-box"></div>
          <div className="fm-promo-image-background-img" style={{ backgroundImage: `url('${ promo.image }')` }}></div>
        </div>
        <div className="fm-promo-image-content">
          <div className="fm-promo-image-content-box">
            <p className="fm-promo-image-title">{ promo.title }</p>
            <p className="fm-promo-image-description">{ promo.text }</p>
          </div>
        </div>
      </div>
    );
  }
}

export default PromoImage;
