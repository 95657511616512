import React, { Component } from 'react';

class AboutImage extends Component {
  render() {
    return (
      <div className="fm-about-image">
        <div className="fm-about-image-background">
          <div className="fm-about-image-background-box"></div>
          <div className="fm-about-image-background-img" style={{ backgroundImage: `url('https://storage.googleapis.com/flamingo-static/images/facade/kiwi-owned.jpg')` }}></div>
        </div>
        <div className="fm-about-image-content">
          <div className="fm-about-image-content-box">
            <p className="fm-about-image-title">About</p>
            <p className="fm-about-image-description">Proudly Kiwi owned & operated electric scooter and bike share.</p>
          </div>
        </div>
      </div>
    );
  }
}

export default AboutImage;
