import React, { Component } from 'react';

class QuizDetails extends Component {

  render() {
    const { question, answer } = this.props;
    const result = question.answers[answer] || {};
    return (
      <div className="fm-quiz-details">
        <p className="fm-quiz-details-title">{ question.subheading }</p>
        <p className="fm-quiz-details-description">{ result.description }</p>
        <p className="fm-quiz-details-terms">{ question.terms }</p>
      </div>
    );
  }
}

export default QuizDetails;
