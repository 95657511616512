import React, { Component } from 'react';

class Safety extends Component {
  render() {
    return (
      <div className="fm-city-download">
        <div className="fm-city-download-container">
          <p className="fm-city-download-title">Ready to ride?</p>
          <p className="fm-city-download-text">Download the Flamingo Scooters app now.</p>
          <div className="fm-city-download-items">
            <a className="fm-city-download-app" href="https://itunes.apple.com/nz/app/flamingo-scooters/id1446388027" target="_blank" rel="noopener noreferrer">
              <img className="fm-city-download-app-img" src="/assets/images/appstore.svg" alt="Download Flamingo on the App Store" />
            </a>
            <a className="fm-city-download-app" href="https://play.google.com/store/apps/details?id=com.flamingoscooters.android" target="_blank" rel="noopener noreferrer">
              <img className="fm-city-download-app-img" src="/assets/images/playstore.svg" alt="Download Flamingo on the Play Store" />
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default Safety;
