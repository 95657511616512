import React, { Component } from 'react';

const contactDetails = {
  'flamingoscooters.com': {
    phoneLabel: '0800 FLAMINGO (352 646)',
    phone: '0800352646',
    email: 'hello@flamingoscooters.com',
    pr: 'media@flamingoscooters.com',
  },
  'flamingoscooters.co.nz': {
    phoneLabel: '0800 FLAMINGO (352 646)',
    phone: '0800352646',
    email: 'hello@flamingoscooters.co.nz',
    pr: 'media@flamingoscooters.co.nz',
  },
  'flamingoscooters.com.au': {
    phoneLabel: '1800 413 646',
    phone: '1800413646',
    email: 'hello@flamingoscooters.com.au',
    pr: 'media@flamingoscooters.com.au',
  },
  'rideflamingo.com.au': {
    phoneLabel: '1800 413 646',
    phone: '1800413646',
    email: 'hello@rideflamingo.com.au',
    pr: 'media@rideflamingo.com.au',
  },
  'rideflamingo.co.nz': {
    phoneLabel: '0800 FLAMINGO (352 646)',
    phone: '0800352646',
    email: 'hello@rideflamingo.co.nz',
    pr: 'media@rideflamingo.co.nz',
  },
  'rideflamingo.com': {
    phoneLabel: '0800 FLAMINGO (352 646)',
    phone: '0800352646',
    email: 'hello@rideflamingo.com',
    pr: 'media@rideflamingo.com',
  },
  'flamingo.co.nz': {
    phoneLabel: '0800 FLAMINGO (352 646)',
    phone: '0800352646',
    email: 'hello@flamingo.co.nz',
    pr: 'media@flamingo.co.nz',
  },
}

class ContactQuick extends Component {
  componentDidMount() {

  }

  render() {
    const contact = contactDetails[window.location.hostname] ? contactDetails[window.location.hostname] : contactDetails['flamingoscooters.com'];
    return (
      <div className="fm-contact-quick">
        <p className="fm-contact-quick-title">Phone</p>
        <a className="fm-contact-quick-link" href={ 'mailto:' + contact.phone }>{ contact.phoneLabel }</a>
        <p className="fm-contact-quick-title">Email</p>
        <a className="fm-contact-quick-link" href={ 'tel:' + contact.email }>{ contact.email }</a>
        <p className="fm-contact-quick-title">Media</p>
        <a className="fm-contact-quick-link" href={ 'mailto:' + contact.pr }>{ contact.pr }</a>
      </div>
    );
  }
}

export default ContactQuick;
