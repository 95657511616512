import React, { Component } from 'react';

class DiscountsTypes extends Component {
  render() {
    return (
      <div className="fm-discounts-types">
        <p className="fm-discounts-types-title">Applicable Card Holders</p>
        <div className="fm-discounts-types-items">
          <div className="fm-discounts-types-item">
            <div className="fm-discounts-types-item-image" style={{ backgroundImage: `url('https://storage.googleapis.com/flamingo-static/images/facade/discounts-student.png')` }}></div>
            <p className="fm-discounts-types-item-title">Students</p>
            <p className="fm-discounts-types-item-description">Students at an accredited higher education institution with valid ID.</p>
          </div>
          <div className="fm-discounts-types-item">
            <div className="fm-discounts-types-item-image" style={{ backgroundImage: `url('https://storage.googleapis.com/flamingo-static/images/facade/discounts-supergold.png')` }}></div>
            <p className="fm-discounts-types-item-title">SuperGold</p>
            <p className="fm-discounts-types-item-description">SuperGold card holders.</p>
          </div>
          <div className="fm-discounts-types-item">
            <div className="fm-discounts-types-item-image" style={{ backgroundImage: `url('https://storage.googleapis.com/flamingo-static/images/facade/discounts-community.png')` }}></div>
            <p className="fm-discounts-types-item-title">Community Services</p>
            <p className="fm-discounts-types-item-description">Community Services Card holders.</p>
          </div>
          <div className="fm-discounts-types-item">
            <div className="fm-discounts-types-item-image" style={{ backgroundImage: `url('https://storage.googleapis.com/flamingo-static/images/facade/discounts-total-mobility.png')` }}></div>
            <p className="fm-discounts-types-item-title">Total Mobility</p>
            <p className="fm-discounts-types-item-description">Total Mobility card holders.</p>
          </div>
          <div className="fm-discounts-types-item">
            <div className="fm-discounts-types-item-image" style={{ backgroundImage: `url('https://storage.googleapis.com/flamingo-static/images/facade/discounts-hapai.png')` }}></div>
            <p className="fm-discounts-types-item-title">Hāpai Access</p>
            <p className="fm-discounts-types-item-description">Hāpai Access card holders.</p>
          </div>
          <div className="fm-discounts-types-item">
            <div className="fm-discounts-types-item-image" style={{ backgroundImage: `url('https://storage.googleapis.com/flamingo-static/images/facade/discounts-leisure.png')` }}></div>
            <p className="fm-discounts-types-item-title">LeisureCard</p>
            <p className="fm-discounts-types-item-description">Wellington City Council LeisureCard holders.</p>
          </div>
        </div>
      </div>
    );
  }
}

export default DiscountsTypes;
