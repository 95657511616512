import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Workplace extends Component {
  render() {
    return (
      <div className="fm-home-splash-workplace">
        <div className="fm-home-splash-workplace-container">
          <p className="fm-home-splash-workplace-title">Workplace Training Sessions</p>
          <p className="fm-home-splash-workplace-description">Encourage active and sustainable transport across your team with a free Flamingo Workplace Training Session. Each session includes a safety briefing and the opportunity for everyone on your team to safely learn how to ride a Flamingo e-scooter and/or e-bike.</p>
          <Link to="/workplace-session" className="fm-home-splash-button">Learn More</Link>
        </div>
      </div>
    );
  }
}

export default Workplace;
