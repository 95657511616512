import React from 'react';

class Polyline extends React.Component {
  constructor() {
    super();
    this.handleClick = this.handleClick.bind(this);
  }

  componentDidUpdate(prevProps) {
    if ((this.props.map !== prevProps.map) || (this.props.encodedPath !== prevProps.encodedPath) || ((this.props.path && prevProps.path && (this.props.path.length !== prevProps.path.length)))) {
      if (this.polyline) {
        this.polyline.setMap(null);
      }
      this.renderPolyline();
    }
  }

  componentWillUnmount() {
    if (this.polyline) {
      this.polyline.setMap(null);
    }
  }

  renderPolyline() {
    let { map, google, path, encodedPath, strokeColor, strokeWeight } = this.props;
    if (encodedPath) {
      path = google.maps.geometry.encoding.decodePath(encodedPath);
    }
    this.polyline = new google.maps.Polyline({ map, path, strokeColor, strokeWeight });
  }

  handleClick() {
    if (this.props.onClick) {
      this.props.onClick();
    }
  }

  render() {
    return null;
  }
}

export default Polyline;
