import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Benefits extends Component {
  render() {
    const { scooter, scooter5, bike } = this.props.vehicles;
    return (
      <div className="fm-city-how-to">
        <div className="fm-city-how-to-container">
          <p className="fm-city-how-to-title">How to Ride</p>
          <div className="fm-city-how-to-boxes">
            {
              scooter &&
              <Link className="fm-city-how-to-box fm-city-how-to-box-scooter" to="/how-to/scooter">
                <div className="fm-city-how-to-box-gradient">
                  <p className="fm-city-how-to-box-title">Flamingo Scooter</p>
                </div>
              </Link>
            }
            {
              scooter5 &&
              <Link className="fm-city-how-to-box fm-city-how-to-box-scooter" to="/how-to/scooter-5">
                <div className="fm-city-how-to-box-gradient">
                  <p className="fm-city-how-to-box-title">Flamingo Scooter</p>
                </div>
              </Link>
            }
            {
              bike &&
              <Link className="fm-city-how-to-box fm-city-how-to-box-bike" to="/how-to/bike">
                <div className="fm-city-how-to-box-gradient">
                  <p className="fm-city-how-to-box-title">Flamingo Bike</p>
                </div>
              </Link>
            }
          </div>
        </div>
      </div>
    );
  }
}

export default Benefits;
