import React, { Component } from 'react';

class DiscountsBlurb extends Component {
  render() {
    return (
      <div className="fm-wlg-promo">
        <div className="fm-wlg-promo-container">
          <p className="fm-wlg-promo-text">Redeem the promo code <strong>AIRPORT</strong> from the "Payment" section of the Flamingo app for <strong>10 minutes free</strong>!</p>
          <p className="fm-wlg-promo-print">1 per customer. Limited time only. This voucher has no monetary value. $1 unlocking fee still applies. The promo code must be redeemed before starting your trip. The 10 minutes of free credit only applies for trips on Flamingo e-scooters in Wellington. Flamingo's Terms of Service applies.</p>
        </div>
      </div>
    );
  }
}

export default DiscountsBlurb;
