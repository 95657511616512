import React, { Component } from 'react';

class SplashImage extends Component {
  render() {
    const { vehicle } = this.props;
    return (
      <div className="fm-how-to-splash-image">
        <div className="fm-how-to-splash-image-background">
          <div className="fm-how-to-splash-image-background-box"></div>
          <div className="fm-how-to-splash-image-background-img" style={{ backgroundImage: `url('${ vehicle.image }')` }}></div>
        </div>
        <div className="fm-how-to-splash-image-content">
          <div className="fm-how-to-splash-image-content-box">
            <p className="fm-how-to-splash-image-title">{ vehicle.name }</p>
            <p className="fm-how-to-splash-image-description">{ vehicle.catch }</p>
          </div>
        </div>
      </div>
    );
  }
}

export default SplashImage;
