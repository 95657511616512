import React, { Component } from 'react';

class DiscountsImage extends Component {
  render() {
    return (
      <div className="fm-wlg-image">
        <div className="fm-wlg-image-background">
          <div className="fm-wlg-image-background-box"></div>
          <div className="fm-wlg-image-background-img" style={{ backgroundImage: `url('https://storage.googleapis.com/flamingo-static/images/facade/wlg.jpg')` }}></div>
        </div>
        <div className="fm-wlg-image-content">
          <div className="fm-wlg-image-content-box">
            <p className="fm-wlg-image-title">Wellington Airport</p>
            <p className="fm-wlg-image-description">Ride from the City to the Airport in 30 minutes.</p>
          </div>
        </div>
      </div>
    );
  }
}

export default DiscountsImage;
