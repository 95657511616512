import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class SafetyLinks extends Component {
  render() {
    return (
      <div className="fm-safety-links">
        <div className="fm-safety-links-boxes">
          <a className="fm-safety-links-box-helmets" href="https://shop.flamingoscooters.com/products/flamingo-helmet" rel="noreferrer" target="_blank">
            <p className="fm-safety-links-box-helmets-title">Free Helmets</p>
          </a>
          <Link className="fm-safety-links-box-city" to="/cities">
            <p className="fm-safety-links-box-city-title">City Safety</p>
          </Link>
        </div>
      </div>
    );
  }
}

export default SafetyLinks;
