import React, { Component } from 'react';
import SafetyImage from './components/safety-image';
import SafetyVideos from './components/safety-videos';
import SafetyBlurb from './components/safety-blurb';
import SafetyLinks from './components/safety-links';

class Safety extends Component {
  componentDidMount() {
    document.title = 'Safety | Flamingo';
  }

  render() {
    return (
      <div className="fm-safety">
        <SafetyImage />
        <SafetyVideos />
        <SafetyBlurb />
        <SafetyLinks />
      </div>
    );
  }
}

export default Safety;
