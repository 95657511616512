import React from 'react';
import Loading from '../loading';
import styles from './flamingo-google-maps-style.json'

class Map extends React.Component {
  constructor() {
    super();
    this.mapContainer = React.createRef();
    this.loadMaps = this.loadMaps.bind(this);
    this.handleDragEnd = this.handleDragEnd.bind(this);
    this.handleMouseEvent = this.handleMouseEvent.bind(this);
    this.renderChildren = this.renderChildren.bind(this);
    this.state = { loading: false, map: null };
  }

  componentDidMount() {
    this.loadMaps();
  }

  componentDidUpdate(prevProps) {
    if (this.props.map !== prevProps.map || this.props.options) {
      if (this.props.options.center.lat !== prevProps.options.center.lat || this.props.options.center.lng !== prevProps.options.center.lng) {
        if (this.map) {
          const center = new window.google.maps.LatLng(this.props.options.center.lat, this.props.options.center.lng);
          this.map.panTo(center);
        }
      }
    }
  }

  componentWillUnmount() {
    window.google.maps.event.clearListeners(this.map, 'bounds_changed');
    this.map = null;
  }

  loadMaps() {
    const options = { center: { lat: -41.288170, lng: 174.778952 }, zoom: 16, ...this.props.options, styles };
    const map = new window.google.maps.Map(this.mapContainer.current, options);
    map.addListener('idle', this.handleDragEnd);
    map.addListener('click', this.handleMouseEvent);
    this.map = map;
    this.setState({ loading: false });
  }

  handleDragEnd(passedMap = false) {
    const bounds = this.map.getBounds();
    const center = this.map.getCenter();
    const ne = bounds.getNorthEast();
    const sw = bounds.getSouthWest();
    const zoom = this.map.getZoom();
    if (this.props.onBoundsChange) {
      this.props.onBoundsChange(ne, sw, zoom);
    }
    if (this.props.isGlobal) {
      window.localStorage.setItem('mapLocation', JSON.stringify({ center, zoom }));
    }
  }

  handleMouseEvent(event) {
    if (this.props.onClick) {
      this.props.onClick(event.latLng.lat(), event.latLng.lng());
    }
  }

  renderChildren() {
    const { children } = this.props;
    if (!children) return;

    return React.Children.map(children, c => {
      if (!c) return null;
      return React.cloneElement(c, {
        map: this.map,
        google: this.props.google,
      });
    })
  }

  render() {
    return (
      <div className="fm-map" ref={this.mapContainer}>
        { this.state.loading && <Loading /> }
        { this.renderChildren() }
      </div>
    );
  }
}

export default Map;
