import React, { Component } from 'react';

class Ride extends Component {
  renderStep(step, i) {
    return (
      <div className="fm-how-to-getting-started-item" key={i}>
        <div className="fm-how-to-getting-started-item-image" style={{ backgroundImage: `url('${ step.image }')` }}></div>
        <p className="fm-how-to-getting-started-item-title">{ i + 1 }. { step.title }</p>
        <p className="fm-how-to-getting-started-item-description">{ step.description }</p>
      </div>
    );
  }

  render() {
    const { vehicle } = this.props;
    return (
      <div className="fm-how-to-getting-started">
        <div className="fm-how-to-getting-started-container">
          <p className="fm-how-to-getting-started-title">Getting Started</p>
          <div className="fm-how-to-getting-started-items">
            { vehicle.started.map(this.renderStep) }
          </div>
        </div>
      </div>
    );
  }
}

export default Ride;
