import React, { Component } from 'react';

class ServiceArea extends Component {
  render() {
    return (
      <div className="fm-city-details">
        <div className="fm-city-details-boxes">
          <div className="fm-city-details-box fm-city-details-box-left">
            <div className="fm-city-details-box-content">
              <p className="fm-city-details-title">{ this.props.city.name } Pricing</p>
              { this.props.city.pricing.map((l, i) => <p className="fm-city-details-line" key={i}>{l}</p>) }
            </div>
          </div>
          <div className="fm-city-details-box fm-city-details-box-right">
            <div className="fm-city-details-box-content">
              <p className="fm-city-details-title">{ this.props.city.name } Service Hours</p>
              { this.props.city.hours.map((l, i) => <p className="fm-city-details-line" key={i}>{l}</p>) }
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ServiceArea;
