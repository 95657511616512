import React, { Component } from 'react';

class AboutSustainability extends Component {
  render() {
    return (
      <div className="fm-about-sustainability">
        <div className="fm-about-sustainability-boxes">
          <div className="fm-about-sustainability-box fm-about-sustainability-box-left"></div>
          <div className="fm-about-sustainability-box fm-about-sustainability-box-right">
            <div className="fm-about-sustainability-box-content">
              <p className="fm-about-sustainability-title">Zero Carbon</p>
              <p className="fm-about-sustainability-line">Flamingo is proud to be certified Zero Carbon Business Operations with Ekos, a NZ organisation who develops carbon projects that grow and protect forests in New Zealand and the Pacific Islands, measures business’ carbon footprints and provides carbon certification. These forest carbon projects deliver climate resilience, waterways protection, erosion control, biodiversity protection and community economic development.</p>
              <p className="fm-about-sustainability-line">Flamingo is the only operator with Carbon Neutral certification issued from a New Zealand certification body.</p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AboutSustainability;
