import React, { Component } from 'react';

class Blurb extends Component {
  render() {
    const { vehicle } = this.props;
    return (
      <div className="fm-how-to-blurb">
        <div className="fm-how-to-blurb-container">
          <p className="fm-how-to-blurb-text">{ vehicle.blurb }</p>
        </div>
      </div>
    );
  }
}

export default Blurb;
