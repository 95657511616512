import React, { Component } from 'react';
import SplashImages from './components/splash-images';
import Locations from './components/locations';
import HowToRide from './components/how-to-ride';
import Benefits from './components/benefits';
import Workplace from './components/workplace';

class Home extends Component {
  componentDidMount() {
    document.title = 'Flamingo';
  }

  render() {
    return (
      <div className="fm-home">
        <SplashImages />
        <Locations />
        <HowToRide />
        <Benefits />
        <Workplace />
      </div>
    );
  }
}

export default Home;
