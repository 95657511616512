import React, { Component } from 'react';

class WorkplaceSessionImage extends Component {
  render() {
    return (
      <div className="fm-workplace-session-image">
        <div className="fm-workplace-session-image-background">
          <div className="fm-workplace-session-image-background-box"></div>
          <div className="fm-workplace-session-image-background-img" style={{ backgroundImage: `url('https://storage.googleapis.com/flamingo-static/images/facade/workplace-session.jpg')` }}></div>
        </div>
        <div className="fm-workplace-session-image-content">
          <div className="fm-workplace-session-image-content-box">
            <p className="fm-workplace-session-image-title">Workplace Sessions</p>
            <p className="fm-workplace-session-image-description">Register your workplace for a free Flamingo training session.</p>
          </div>
        </div>
      </div>
    );
  }
}

export default WorkplaceSessionImage;
