import React from 'react';
import { Link } from 'react-router-dom';
import history from '../../../helpers/history';

class TopBar extends React.Component {
  constructor() {
    super();
    this.state = { mobileMenuOpen: false };
    this.toggleMenu = this.toggleMenu.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleDownloadClick = this.handleDownloadClick.bind(this);
    this.openIOS = this.openIOS.bind(this);
    this.openAndroid = this.openAndroid.bind(this);
  }

  toggleMenu() {
    this.setState({ mobileMenuOpen: !this.state.mobileMenuOpen });
  }

  handleClick() {
    this.setState({ mobileMenuOpen: false });
  }

  handleDownloadClick() {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    // Android
    if (/android/i.test(userAgent)) {
      return this.openAndroid();
    }
    // iOS
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return this.openIOS();
    }
    // Other
    return history.push('/ride');
  }

  openIOS() {
    window.location = 'com.flamingoscooters://';
    setTimeout(() => {
      window.location = 'itms-apps://itunes.apple.com/nz/app/flamingo-scooters/id1446388027';
      setTimeout(() => {
        window.location = 'https://itunes.apple.com/nz/app/flamingo-scooters/id1446388027';
      }, 250);
    }, 250);
  }

  openAndroid() {
    window.location = 'https://play.google.com/store/apps/details?id=com.flamingoscooters.android';
  }

  render() {
    return (
      <div className={ this.state.mobileMenuOpen ? 'fm-top-bar fm-top-bar-open' : 'fm-top-bar' }>
        <div className="fm-top-bar-content">
          <div className="fm-top-bar-brand">
            <Link className="fm-top-bar-wordmark" to="/" onClick={this.handleClick}><img className="fm-top-bar-wordmark-img" src="https://storage.googleapis.com/flamingo-static/images/facade/wordmark-pink.svg" alt="Flamingo"/><span className="fm-top-bar-wordmark-tm">TM</span></Link>
          </div>
          <div className="fm-top-bar-mobile" onClick={this.toggleMenu}></div>
          <div className="fm-top-bar-links">
            <div className="fm-top-bar-links-left">
              <Link className="fm-top-bar-link" to="/cities" onClick={this.handleClick}>Cities</Link>
              <Link className="fm-top-bar-link" to="/safety" onClick={this.handleClick}>Safety</Link>
              <Link className="fm-top-bar-link" to="/about" onClick={this.handleClick}>About</Link>
              <Link className="fm-top-bar-link" to="/contact" onClick={this.handleClick}>Contact</Link>
            </div>
            <div className="fm-top-bar-links-right">
              <Link className="fm-top-bar-link fm-top-bar-link-special" to="#" onClick={this.handleDownloadClick}>Download</Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default TopBar;
