import React, { Component } from 'react';

const splashDescription = {
  'flamingoscooters.com': 'Proudly New Zealand owned & operated electric scooter and bike share.',
  'flamingoscooters.co.nz': 'Proudly New Zealand owned & operated electric scooter and bike share.',
  'flamingoscooters.com.au': 'Australia & New Zealand based electric scooter and bike share.',
  'rideflamingo.com.au': 'Australia & New Zealand based electric scooter and bike share.',
  'rideflamingo.co.nz': 'Proudly New Zealand owned & operated electric scooter and bike share.',
  'rideflamingo.com': 'Proudly New Zealand owned & operated electric scooter and bike share.',
  'flamingo.co.nz': 'Proudly New Zealand owned & operated electric scooter and bike share.',
};

class SplashImages extends Component {
  render() {
    const description = splashDescription[window.location.hostname] ? splashDescription[window.location.hostname] : splashDescription['flamingoscooters.com'];
    return (
      <div className="fm-home-splash-images">
        <div className="fm-home-splash-images-background">
          <div className="fm-home-splash-images-background-box"></div>
          <img className="fm-home-splash-images-background-img-back" src="https://storage.googleapis.com/flamingo-static/images/facade/hero-1.jpg" alt="" />
          <img className="fm-home-splash-images-background-img-top" src="https://storage.googleapis.com/flamingo-static/images/facade/hero-2.jpg" alt="" />
        </div>
        <div className="fm-home-splash-images-content">
          <div className="fm-home-splash-images-content-box">
            <p className="fm-home-splash-images-title">Ride</p>
            <p className="fm-home-splash-images-title">Your Way</p>
            <p className="fm-home-splash-images-description">{ description }</p>
            <a className="fm-home-splash-images-download-ios" href="https://itunes.apple.com/nz/app/flamingo-scooters/id1446388027">
              <div className="fm-home-splash-images-download-hover"></div>
              <p className="fm-home-splash-images-download-text">Download iOS</p>
            </a>
            <a className="fm-home-splash-images-download-android" href="https://play.google.com/store/apps/details?id=com.flamingoscooters.android">
              <div className="fm-home-splash-images-download-hover"></div>
              <p className="fm-home-splash-images-download-text">Download Android</p>
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default SplashImages;
