import React, { Component } from 'react';
import QuizImage from './components/quiz-image';
import QuizDetails from './components/quiz-details';
import history from '../../helpers/history';

const questions = {
  cuba: {
    title: 'Cuba Quiz',
    image: 'https://storage.googleapis.com/flamingo-static/images/facade/join-plan.jpg',
    subheading: '10 Minutes Free',
    terms: 'Promotion ends 28 February 2022. 1 per customer. $1 unlocking fee still applies. This voucher has no monetary value.',
    answers: {
      no: {
        text: `Well done! That's correct, you cannot ride your Flamingo down Cuba Mall.`,
        description: <>Enter the voucher code "<b>CUBA</b>" from the Payment section of the Flamingo app for 10 minutes FREE!</>,
      },
      yes: {
        text: `Oops! The correct answer is No. You cannot ride your Flamingo down Cuba Mall.`,
        description: <>The correct answer is no, you cannot ride your Flamingo down Cuba Mall. Have a look at the email and try again!</>,
      },
    },
  },
  goldenmile: {
    title: 'The Golden Mile Quiz',
    image: 'https://storage.googleapis.com/flamingo-static/images/email/golden-mile.png',
    subheading: '10 Minutes Free',
    terms: 'Promotion ends 30 September 2022. 1 per customer. $1 unlocking fee still applies. This voucher has no monetary value.',
    answers: {
      road: {
        text: `Well done! That's correct, you can only ride on the road when travelling down the Golden Mile.`,
        description: <>Enter the voucher code "<b>GOLDENROAD</b>" from the Payment section of the Flamingo app for 10 minutes FREE!</>,
      },
      footpath: {
        text: `Oops! The correct answer is "Road". You cannot ride your Flamingo on the footpath of The Golden Mile.`,
        description: <>You can only ride on the road when travelling down the Golden Mile (Lambton/Willis/Manners/Courtenay). Have a look at the email and try again!</>,
      },
    },
  },
  ritaangus: {
    title: 'Rita Angus Retirement Village Quiz',
    image: 'https://storage.googleapis.com/flamingo-static/images/email/rita-angus.png',
    subheading: '10 Minutes Free',
    terms: 'Promotion ends 30 November 2024. 1 per customer. $1 unlocking fee still applies. This voucher has no monetary value.',
    answers: {
      road: {
        text: `Well done! That's correct, whenever possible, please ride on the road instead of the footpath to help protect pedestrians entering or leaving the retirement village.`,
        description: <>Enter the voucher code "<b>COUTTS</b>" from the Payment section of the Flamingo app for 10 minutes FREE!</>,
      },
      footpath: {
        text: `Oops! The correct answer is "Road". Whenever possible, please ride on the road instead of the footpath to help protect pedestrians entering or leaving the retirement village.`,
        description: <>Have a look at the email and try again!</>,
      },
    },
  },
  highst: {
    title: 'High Street Quiz',
    image: 'https://storage.googleapis.com/flamingo-static/images/facade/rangiora.jpg',
    subheading: '10 Minutes Free',
    terms: 'Promotion ends 31 December 2022. 1 per customer. $1 unlocking fee still applies. This voucher has no monetary value.',
    answers: {
      no: {
        text: `Well done! That's correct, you cannot ride on High Street between Durham Street and Ashley Street.`,
        description: <>Enter the voucher code "<b>HIGHST</b>" from the Payment section of the Flamingo app for 10 minutes FREE!</>,
      },
      yes: {
        text: `Oops! The correct answer is "No". You cannot ride on High Street between Durham Street and Ashley Street.`,
        description: <>You can only ride on High Street west of Ashley Street and east of Durham Street. Have a look at the email and try again!</>,
      },
    },
  },
};

class Quiz extends Component {
  constructor(props) {
    super(props);
    this.state = { question: questions[props.match.params.question] };
  }

  componentDidMount() {
    document.title = 'Quiz | Flamingo';
    if (!this.state.question) {
      history.push('/');
    }
  }

  render() {
    if (!this.state.question) {
      return null;
    }

    return (
      <div className="fm-quiz">
        <QuizImage question={this.state.question} answer={this.props.match.params.answer} />
        <QuizDetails question={this.state.question} answer={this.props.match.params.answer} />
      </div>
    );
  }
}

export default Quiz;
