import React, { Component } from 'react';

class DiscountsImage extends Component {
  render() {
    return (
      <div className="fm-discounts-image">
        <div className="fm-discounts-image-background">
          <div className="fm-discounts-image-background-box"></div>
          <div className="fm-discounts-image-background-img" style={{ backgroundImage: `url('https://storage.googleapis.com/flamingo-static/images/facade/discounts.jpg?a')` }}></div>
        </div>
        <div className="fm-discounts-image-content">
          <div className="fm-discounts-image-content-box">
            <p className="fm-discounts-image-title">Community & Student Discounts</p>
            <p className="fm-discounts-image-description">Save up to 50% on your rides!</p>
          </div>
        </div>
      </div>
    );
  }
}

export default DiscountsImage;
