import React, { Component } from 'react';

class DiscountsTypes extends Component {
  render() {
    return (
      <div className="fm-about-benefits">
        <div className="fm-about-benefits-items">
          <div className="fm-about-benefits-item">
            <div className="fm-about-benefits-item-image" style={{ backgroundImage: `url('https://storage.googleapis.com/flamingo-static/images/facade/about-safety.png')` }}></div>
            <p className="fm-about-benefits-item-title">Safety First</p>
            <p className="fm-about-benefits-item-description">Safety is our top priority at Flamingo and number one core value. We believe that offering a safe and fun scooter and bike transport service is not only a possibility but a responsibility. We approach safety from both a customer and a community perspective which allows us to identify, evaluate and manage risks involved in both using our services as well as for those sharing public spaces with our customers.</p>
          </div>
          <div className="fm-about-benefits-item">
            <div className="fm-about-benefits-item-image" style={{ backgroundImage: `url('https://storage.googleapis.com/flamingo-static/images/facade/about-community.png')` }}></div>
            <p className="fm-about-benefits-item-title">Community Focus</p>
            <p className="fm-about-benefits-item-description">Flamingo takes our role in each local community seriously and values becoming a visible part of each community, adding a new level of fun and vibrancy to the population. In addition, our ability to connect the first and last mile of transport, and to enable personal transportation for longer distances plays a positive part in connecting people together and helping them enjoy their communities and facilities more. </p>
          </div>
          <div className="fm-about-benefits-item">
            <div className="fm-about-benefits-item-image" style={{ backgroundImage: `url('https://storage.googleapis.com/flamingo-static/images/facade/about-sustainable.png')` }}></div>
            <p className="fm-about-benefits-item-title">Sustainable at Heart</p>
            <p className="fm-about-benefits-item-description">Since our establishment, Flamingo has had sustainability as one of our core values. Flamingo is leading the way in sustainability with it driving all management decisions across the entire organisation. We value contributing to environmental sustainability in partnership with Councils and are proud to be the only e-scooter operator Zero Carbon certified from a NZ certification body.</p>
          </div>
        </div>
      </div>
    );
  }
}

export default DiscountsTypes;
