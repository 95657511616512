import React, { Component } from 'react';
import Loading from '../../../common/loading';

class Safety extends Component {
  render() {
    const { vehicle, loading, instruction } = this.props;

    return (
      <div className="fm-how-to-safety">
        <div className="fm-how-to-safety-container">
          <p className="fm-how-to-safety-title">{ vehicle.name } Safety</p>
          <p className="fm-how-to-safety-text">Safety is the top priority for Flamingo. We want to ensure everyone is kept safe whilst still maintaining a fun experience. Follow these guidelines when riding a Flamingo.</p>
          {
            loading ? (
              <div className="fm-how-to-safety-loading">
                <Loading />
              </div>
            ) : (
              <div className="fm-how-to-safety-items">
                {
                  instruction.items.map((item, i) => {
                    return (
                      <div className="fm-how-to-safety-item" key={i}>
                        <div className="fm-how-to-safety-item-image" style={{ backgroundImage: `url('${item.photoUrl}')` }}></div>
                        <p className="fm-how-to-safety-item-title">{ item.title }</p>
                        <p className="fm-how-to-safety-item-message">{ item.description }</p>
                      </div>
                    );
                  })
                }
              </div>
            )
          }
        </div>
      </div>
    );
  }
}

export default Safety;
