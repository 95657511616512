import React from 'react';

class Polygon extends React.Component {
  constructor() {
    super();
    this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount() {
    this.renderPolygon();
  }

  componentDidUpdate(prevProps) {
    if ((this.props.map !== prevProps.map) || (this.props.paths !== prevProps.paths)) {
      if (this.polygon) {
        this.polygon.setMap(null);
      }
      this.renderPolygon();
    }
  }

  componentWillUnmount() {
    if (this.polygon) {
      this.polygon.setMap(null);
    }
  }

  renderPolygon() {
    const { map, google, paths, strokeColor, strokeWeight, fillColor, fillOpacity } = this.props;
    this.polygon = new google.maps.Polygon({ map, paths, strokeColor, strokeWeight, fillColor, fillOpacity });
    this.polygon.addListener('click', this.handleClick);
  }

  handleClick() {
    if (this.props.onClick) {
      this.props.onClick();
    }
  }

  render() {
    return null;
  }
}

export default Polygon;
