import React, { Component } from 'react';

class WorkplaceSessionBlurb extends Component {
  render() {
    return (
      <div className="fm-workplace-session-blurb">
        <div className="fm-workplace-session-blurb-container">
          <p className="fm-workplace-session-blurb-text">Each session includes a safety briefing and the opportunity for everyone on your team to safely learn how to ride a Flamingo e-scooter and/or e-bike. As a Kiwi owned and operated company Flamingo is committed to encouraging active and sustainable transport across our cities.</p>
        </div>
      </div>
    );
  }
}

export default WorkplaceSessionBlurb;
