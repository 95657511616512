import React, { Component } from 'react';

class QuizImage extends Component {
  render() {
    const { question, answer } = this.props;
    const result = question.answers[answer] || {};
    return (
      <div className="fm-quiz-image">
        <div className="fm-quiz-image-background">
          <div className="fm-quiz-image-background-box"></div>
          <div className="fm-quiz-image-background-img" style={{ backgroundImage: `url('${ question.image }')` }}></div>
        </div>
        <div className="fm-quiz-image-content">
          <div className="fm-quiz-image-content-box">
            <p className="fm-quiz-image-title">{ question.title }</p>
            <p className="fm-quiz-image-description">{ result.text }</p>
          </div>
        </div>
      </div>
    );
  }
}

export default QuizImage;
