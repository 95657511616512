import React, { Component } from 'react';
import Loading from '../common/loading';

class Helmets extends Component {
  componentDidMount() {
    document.title = 'Helmets | Flamingo';
    window.location = 'https://shop.flamingoscooters.com/products/flamingo-helmet';
  }

  render() {
    return (
      <div className="fm-helmets">
        <Loading />
      </div>
    );
  }
}

export default Helmets;
