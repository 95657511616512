import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';

class Footer extends React.Component {
  render() {
    return (
      <div className="fm-footer">
        <div className="fm-footer-content">
          <img className="fm-footer-logo" src="/assets/images/logo.svg" alt="Flamingo Logo" />
          <Link className="fm-footer-wordmark" to="/"><img className="fm-footer-wordmark-img" src="https://storage.googleapis.com/flamingo-static/images/facade/wordmark.svg" alt="Flamingo"/><span className="fm-footer-wordmark-tm">TM</span></Link>
          <div className="fm-footer-links">
            <div className="fm-footer-link-column">
              <Link className="fm-footer-link" to="/cities">
                <span className="fm-footer-link-text">Cities</span>
              </Link>
              <Link className="fm-footer-link" to="/safety">
                <span className="fm-footer-link-text">Safety</span>
              </Link>
              <Link className="fm-footer-link" to="/about">
                <span className="fm-footer-link-text">About</span>
              </Link>
              <Link className="fm-footer-link" to="/how-to/scooter">
                <span className="fm-footer-link-text">How To</span>
              </Link>
            </div>
            <div className="fm-footer-link-column">
              <Link className="fm-footer-link" to="/careers">
                <span className="fm-footer-link-text">Careers</span>
              </Link>
              <Link className="fm-footer-link" to="/contact">
                <span className="fm-footer-link-text">Contact</span>
              </Link>
              <Link className="fm-footer-link" to="/terms">
                <span className="fm-footer-link-text">Terms of Use</span>
              </Link>
              <Link className="fm-footer-link" to="/privacy">
                <span className="fm-footer-link-text">Privacy Policy</span>
              </Link>
            </div>
            <div className="fm-footer-link-column">
              <a className="fm-footer-link" href="https://www.facebook.com/FlamingoScooters" target="_blank" rel="noopener noreferrer">
                <span className="fm-footer-link-text">Facebook</span>
              </a>
              <a className="fm-footer-link" href="https://twitter.com/FlamingoScooter" target="_blank" rel="noopener noreferrer">
                <span className="fm-footer-link-text">Twitter</span>
              </a>
              <a className="fm-footer-link" href="https://www.instagram.com/flamingoscooters" target="_blank" rel="noopener noreferrer">
                <span className="fm-footer-link-text">Instagram</span>
              </a>
              <a className="fm-footer-link" href="https://www.tiktok.com/@rideflamingo" target="_blank" rel="noopener noreferrer">
                <span className="fm-footer-link-text">TikTok</span>
              </a>
            </div>
            <div className="fm-footer-link-column fm-footer-apps">
              <a className="fm-footer-app" href="https://itunes.apple.com/nz/app/flamingo-scooters/id1446388027" target="_blank" rel="noopener noreferrer">
                <img className="fm-footer-app-img" src="/assets/images/appstore.svg" alt="Download Flamingo on the App Store" />
              </a>
              <a className="fm-footer-app" href="https://play.google.com/store/apps/details?id=com.flamingoscooters.android" target="_blank" rel="noopener noreferrer">
                <img className="fm-footer-app-img" src="/assets/images/playstore.svg" alt="Download Flamingo on the Play Store" />
              </a>
            </div>
          </div>
          <p className="fm-footer-copy">&copy; Flamingo Technologies Limited { moment().format('YYYY') }</p>
        </div>
      </div>
    );
  }
}

export default Footer;
