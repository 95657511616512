import React, { Component } from 'react';
import PageHeading from '../common/page-heading';

class Ride extends Component {
  componentDidMount() {
    document.title = 'Download | Flamingo';
  }

  render() {
    return (
      <div className="fm-ride">
        <PageHeading image="https://storage.googleapis.com/flamingo-static/images/facade/flamingos.jpeg" title="Download" />
        <div className="fm-container fm-ride-content">
          <p className="fm-text">To ride a Flamingo Scooter, download the Flamingo App from the Apple App Store or Google Play Store.</p>
          <a className="fm-ride-app" href="https://itunes.apple.com/nz/app/flamingo-scooters/id1446388027" target="_blank" rel="noopener noreferrer">
            <img className="fm-ride-app-img" src="/assets/images/appstore.svg" alt="Download Flamingo on the App Store" />
          </a>
          <a className="fm-ride-app" href="https://play.google.com/store/apps/details?id=com.flamingoscooters.android" target="_blank" rel="noopener noreferrer">
            <img className="fm-ride-app-img" src="/assets/images/playstore.svg" alt="Download Flamingo on the Play Store" />
          </a>
        </div>
      </div>
    );
  }
}

export default Ride;
