import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class AboutFollow extends Component {
  render() {
    return (
      <div className="fm-about-follow">
        <div className="fm-about-follow-container">
          <p className="fm-about-follow-title">Start Your Flamingo Journey</p>
          <p className="fm-about-follow-description">Follow us on social media to keep up with the latest Flamingo news. Would you like to see Flamingo in your city? Contact us below to find out about how a Flamingo trial could work in your city.</p>
          <div className="fm-about-socials">
            <a href="https://www.facebook.com/FlamingoScooters/" target="_blank" rel="noopener noreferrer" className="fm-about-social fm-about-social-facebook" alt="Flamingo on Facebook">Facebook</a>
            <a href="https://www.instagram.com/flamingoscooters/" target="_blank" rel="noopener noreferrer" className="fm-about-social fm-about-social-instagram" alt="Flamingo on Instagram">Instagram</a>
            <a href="https://twitter.com/FlamingoScooter" target="_blank" rel="noopener noreferrer" className="fm-about-social fm-about-social-twitter" alt="Flamingo on Twitter">Twitter</a>
            <a href="https://www.tiktok.com/@rideflamingo" target="_blank" rel="noopener noreferrer" className="fm-about-social fm-about-social-tiktok" alt="Flamingo on TikTok">TikTok</a>
            <a href="https://www.linkedin.com/company/flamingo-scooters/" target="_blank" rel="noopener noreferrer" className="fm-about-social fm-about-social-linkedin" alt="Flamingo on LinkedIn">Linkedin</a>
          </div>
          <Link to="/contact" className="fm-about-button">Contact Us</Link>
        </div>
      </div>
    );
  }
}

export default AboutFollow;
