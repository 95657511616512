import React, { Component } from 'react';
import PageHeading from '../common/page-heading';

class Privacy extends Component {
  componentDidMount() {
    document.title = 'Privacy | Flamingo';
  }

  render() {
    return (
      <div className="fm-privacy">
        <PageHeading image="https://storage.googleapis.com/flamingo-static/images/facade/metal.jpg" title="Privacy Policy" />
        <div className="fm-container fm-privacy-content">
          <div className="fm-privacy-section">
            <p className="fm-privacy-list-header">Introduction</p>
            <p className="fm-privacy-list-text">Flamingo Technologies Limited ("<b>Flamingo, We, Us, Our</b>") complies with the New Zealand Privacy Act 1993 ("the <b>Act</b>") when dealing with personal information. Personal information is information about an identifiable individual (a natural person). This policy sets out how We will collect, use, disclose and protect your personal information. This policy does not limit or exclude any of your rights under the Act. If you wish to seek further information on the Act, see <a className="fm-privacy-link" href="http://www.privacy.org.nz">www.privacy.org.nz</a>. This policy should be read in conjunction with Flamingo's Terms of Service <a className="fm-privacy-link" href="https://flamingoscooters.com/terms">flamingoscooters.com/terms</a> (and any capitalised terms not defined here carry the same meaning as in the Terms of Service). For the removal of doubt the definitions and interpretation provisions of the Terms of Service apply to this policy.</p>
            <p className="fm-privacy-list-text">Please read this Policy with the Terms of Service which can be accessed at <a className="fm-privacy-link" href="https://flamingoscooters.com/terms">flamingoscooters.com/terms</a></p>
            <p className="fm-privacy-list-text">By accessing or using the Service, you agree you have read this Policy and the Terms of Service and you agree to this Policy.</p>
          </div>
          <div className="fm-privacy-section">
            <p className="fm-privacy-list-header">Changes to this policy</p>
            <p className="fm-privacy-list-text">We may change this policy by uploading a revised policy onto the Website. The change will apply from the date that We upload the revised policy. Please review this policy periodically, and especially before you provide Us with any information. Your continued use of the Service after any changes or revisions to this policy or after being given the opportunity to assent to the revised policy shall indicate your agreement with the terms of such revised policy.</p>
            <p className="fm-privacy-list-text">This Privacy Policy was last updated in May 2019.</p>
          </div>
          <div className="fm-privacy-section">
            <p className="fm-privacy-list-header">Who do We collect your personal information from</p>
            <p className="fm-privacy-list-text">We collect personal information about you from:</p>
            <ul className="fm-privacy-list">
              <li className="fm-privacy-list-item">
                <p className="fm-privacy-list-text">you, when you provide that personal information to Us, including via the App, Website and any related service, any registration or subscription process, any contact with Us (e.g. telephone call or email), your participation in polls, sweepstakes, or surveys, requests or questions you submit to Us via online forms or other customer support, your transactions with Us, when you register for an event We sponsor, when you authorise Us to collect your social media information, when you submit an application to work at Flamingo, and any other communication you make with Us through the Service; or</p>
              </li>
              <li className="fm-privacy-list-item">
                <p className="fm-privacy-list-text">third parties or the information is publicly available;</p>
              </li>
              <li className="fm-privacy-list-item">
                <p className="fm-privacy-list-text">law enforcement, local authority or other third party monitoring of your use of our Services.</p>
              </li>
            </ul>
            <p className="fm-privacy-list-text">If possible, We will collect personal information from you directly.</p>
          </div>
          <div className="fm-privacy-section">
            <p className="fm-privacy-list-header">Information We collect</p>
            <p className="fm-privacy-list-text">When We use the Service, We collect certain information automatically as described in this Section. As discussed further below, We and Our service providers (which are third party companies that work on Our behalf), may use a variety of technologies, including cookies and similar tools, to assist in collecting this information.</p>
            <p className="fm-privacy-list-text"><b>User Provided Information:</b> When you register and set up an Account, use or visit the Service, or contact Us by email, telephone or other means for any reason, you may provide personal information to Flamingo. This may include but is not limited to your name, date of birth, email and phone number. In addition, from time to time We may collect other personal information you provide in connection with your participation in promotional offers and other activities on the Service. If you use the Service with your mobile device, We may have access to your phone number, Unique Device Identifier or other device information that uniquely identifies individual smartphones or mobile devices.</p>
            <p className="fm-privacy-list-text"><b>Payment Information:</b> If you rent Scooters from the Service, We will collect the billing and financial information necessary to process your charges, which may include your email and postal address. We may also receive the billing and payment information that you provide when your purchase is processed by another party.</p>
            <p className="fm-privacy-list-text"><b>Device/Usage Information:</b> We and Our third-party service providers, who include but are not limited to Google Analytics, may use cookies, web beacons, and other tracking technologies to collect information about the computers or devices (including mobile devices) you use to access the Service. As described further below, We may collect and analyse information including but not limited to (a) browser type; (b) ISP or operating system; (c) domain name; (d) access time; (e) referring or exit pages; (f) page views; (g) IP address; (h) unique device identifiers (e.g. IDFA or Android ID); and (i) the type of device that you use. We may also track how frequently you access or use the Service. We use this information (including the information collected by Our third-party service providers) for analytics (including to determine which portions of the Service are used most frequently and what Our users like/do not like), to assist in determining relevant advertising (both on and off the Service), to evaluate the success of Our advertising campaigns, and as otherwise described in this policy.</p>
            <p className="fm-privacy-list-text"><b>Location Information:</b> When you use the Service, We and Our service providers may automatically collect general location information (e.g., IP address, city and or postal code associated with an IP address) from your computer or mobile device. If you install Our App, We may ask you to grant Us access to your mobile device's geolocation data. If you grant such permission, We may collect information about your precise geolocation, and We may use that information to improve the Service and provide you with location-based features (e.g. notify you of Scooters available near you). When you are renting a Scooter, We collect information about the location of the Scooter, the routes taken by the Scooter, and the rental status of the Scooter. While you can disable the GPS or other location-tracking functions on your device, provided your device allows you to do this, you must agree to provide certain types of location information, including, for example the location of any Scooter while you are renting it, if you want to use Our Service.</p>
            <p className="fm-privacy-list-text"><b>Cookies and Other Electronic Technologies:</b> We and Our third-party service providers may use cookies, clear GIFs, pixel tags, and other technologies that help Us better understand user behaviour, personalise preferences, perform research and analytics, and improve the Service. These technologies, for example, may allow Us to tailor the Service to your needs, save your password in password-protected areas, track the pages you visit, help Us manage content, and compile statistics about usage of the Service. We may also use these technologies to deliver advertisements through the Service that may interest you. We or Our third-party service providers also may use these technologies in emails to Our customers to help Us track email response rates, identify when Our emails are viewed, and track whether Our emails are forwarded.</p>
            <p className="fm-privacy-list-text">You can choose to accept or decline cookies. Most web browsers automatically accept cookies, but your browser may allow you to modify your browser settings to decline cookies if you prefer. If you disable cookies, you may be prevented from taking full advantage of the Service, because it may not function properly. As We adopt additional technologies, We may also gather additional information through other methods.</p>
            <p className="fm-privacy-list-text"><b>Information about Third Parties We collect from Referrals:</b> We may from time to time conduct a referral service to introduce people you know to Our App and Service. If you choose to use Our referral service to tell someone about Our App and Service or a discount on the App and Service, We will ask you for your contact’s name, email and/or phone number. We will automatically send your contact a one-time email and/or text message inviting him or her to visit Our App. We store this information for the purpose of sending this one-time email and/or text message and tracking the success of Our referral program. Your contact may contact Us at  legal@flamingoscooters.com to request that We remove this information from Our database.</p>
            <p className="fm-privacy-list-text"><b>Other Sources:</b> We also may collect personal information from other sources or from Our offline interactions with you to, among other things, enable Us to verify, update information contained in Our records and to better customise the App or Website for you. We may also collect personal information about you from credit reporting agencies, ID checking services and criminal conviction or sex offender registries.</p>
          </div>
          <div className="fm-privacy-section">
            <p className="fm-privacy-list-header">How We use your personal information</p>
            <p className="fm-privacy-list-text">We will use your personal information to provide the Service to you, and to enable your use of the Service. Use of your personal information in this manner will include the following;</p>
            <ul className="fm-privacy-list">
              <li className="fm-privacy-list-item">
                <p className="fm-privacy-list-text">to verify your identity;</p>
              </li>
              <li className="fm-privacy-list-item">
                <p className="fm-privacy-list-text">to include determine your service eligibility and credit-worthiness and accessing your driving safety records;</p>
              </li>
              <li className="fm-privacy-list-item">
                <p className="fm-privacy-list-text">to track the Scooters;</p>
              </li>
              <li className="fm-privacy-list-item">
                <p className="fm-privacy-list-text">to register you for an Account;</p>
              </li>
              <li className="fm-privacy-list-item">
                <p className="fm-privacy-list-text">to maintain internal records;</p>
              </li>
              <li className="fm-privacy-list-item">
                <p className="fm-privacy-list-text">to communicate with you regarding your relationship with Us;</p>
              </li>
              <li className="fm-privacy-list-item">
                <p className="fm-privacy-list-text">to communicate with you about changes to Our policies;</p>
              </li>
              <li className="fm-privacy-list-item">
                <p className="fm-privacy-list-text">to market the Service to you, including contacting you electronically (e.g. by text message or email for this purpose);</p>
              </li>
              <li className="fm-privacy-list-item">
                <p className="fm-privacy-list-text">to personalise, customise and improve your experience and usage of the Service that We provide to you;</p>
              </li>
              <li className="fm-privacy-list-item">
                <p className="fm-privacy-list-text">to send you notices and suggestions;</p>
              </li>
              <li className="fm-privacy-list-item">
                <p className="fm-privacy-list-text">to send you push notifications to your wireless device to provide updates and other relevant messages (you can manage push notifications by following instructions of your device manufacturer);</p>
              </li>
              <li className="fm-privacy-list-item">
                <p className="fm-privacy-list-text">to display relevant products, offers or deals to you;</p>
              </li>
              <li className="fm-privacy-list-item">
                <p className="fm-privacy-list-text">to administer surveys, sweepstakes, promotions, or contests;</p>
              </li>
              <li className="fm-privacy-list-item">
                <p className="fm-privacy-list-text">to undertake background checks of you;</p>
              </li>
              <li className="fm-privacy-list-item">
                <p className="fm-privacy-list-text">to undertake credit checks of you (if necessary);</p>
              </li>
              <li className="fm-privacy-list-item">
                <p className="fm-privacy-list-text">to bill you and to collect money that you owe Us, including authorising and processing credit card transactions and sharing with third-party payment gateways and payment service providers in connection with the Service;</p>
              </li>
              <li className="fm-privacy-list-item">
                <p className="fm-privacy-list-text">to respond to communications from you, including a complaint;</p>
              </li>
              <li className="fm-privacy-list-item">
                <p className="fm-privacy-list-text">to conduct research and statistical, demographic and marketing analyses of users of the Website, App and Website;</p>
              </li>
              <li className="fm-privacy-list-item">
                <p className="fm-privacy-list-text">to improve and maintain the Service, and for product and service development;</p>
              </li>
              <li className="fm-privacy-list-item">
                <p className="fm-privacy-list-text">to automatically update the App on your device;</p>
              </li>
              <li className="fm-privacy-list-item">
                <p className="fm-privacy-list-text">to protect and/or enforce Our legal rights and interests, including defending any claim;</p>
              </li>
              <li className="fm-privacy-list-item">
                <p className="fm-privacy-list-text">to address fraud or safety concerns, or to resolve disputes, protect ourselves and third parties, and to enforce any legal terms that govern your use of the Service;</p>
              </li>
              <li className="fm-privacy-list-item">
                <p className="fm-privacy-list-text">to detect, investigate, and prevent activities that may violate Our policies or be illegal;</p>
              </li>
              <li className="fm-privacy-list-item">
                <p className="fm-privacy-list-text">in any other way to verify accounts and activity, or to promote safety and security of the Service;</p>
              </li>
              <li className="fm-privacy-list-item">
                <p className="fm-privacy-list-text">to comply with law enforcement, other government and local authority agencies;</p>
              </li>
              <li className="fm-privacy-list-item">
                <p className="fm-privacy-list-text">for any other purposes directly related to the above purposes;</p>
              </li>
              <li className="fm-privacy-list-item">
                <p className="fm-privacy-list-text">for any other purposes which you would reasonably expect, which We inform you when We collect your personal information, or which We obtain your consent for; and</p>
              </li>
              <li className="fm-privacy-list-item">
                <p className="fm-privacy-list-text">for any other purpose authorised by you or the Act.</p>
              </li>
            </ul>
            <p className="fm-privacy-list-text">We may aggregate and/or de-identify any information collected through the Service so that such information can no longer be linked to you or your device. We may use such information for any purpose, including without limitation for research and marketing purposes, and may also share such data with any affiliate or other third party.</p>
            <p className="fm-privacy-list-text">You can use the Service without providing Us with information. If you choose not to provide Us with information, your choice will restrict your ability to access some content and use some of the functionality of the Website, App and Service.</p>
          </div>
          <div className="fm-privacy-section">
            <p className="fm-privacy-list-header">Disclosing your personal information</p>
            <p className="fm-privacy-list-text">We may disclose your personal information to:</p>
            <ul className="fm-privacy-list">
              <li className="fm-privacy-list-item">
                <p className="fm-privacy-list-text">any user that you interact with or that interacts with you within the Service;</p>
              </li>
              <li className="fm-privacy-list-item">
                <p className="fm-privacy-list-text">another company within Our group;</p>
              </li>
              <li className="fm-privacy-list-item">
                <p className="fm-privacy-list-text">any business that supports the Service, including but not limited to any person that hosts or maintains any Underlying Systems or data centre that We use to provide the Website, App or Service;</p>
              </li>
              <li className="fm-privacy-list-item">
                <p className="fm-privacy-list-text">a credit reference agency for the purpose of credit checking you;</p>
              </li>
              <li className="fm-privacy-list-item">
                <p className="fm-privacy-list-text">an ID checking service used to verify your personal information;</p>
              </li>
              <li className="fm-privacy-list-item">
                <p className="fm-privacy-list-text">any payment processing company that processes transactions;</p>
              </li>
              <li className="fm-privacy-list-item">
                <p className="fm-privacy-list-text">any of Our affiliates as needed to operate the Service;</p>
              </li>
              <li className="fm-privacy-list-item">
                <p className="fm-privacy-list-text">third party-web analytics services We use on the Service, such as those of Google Analytics. These service providers use the sort of technology described in the section above titled Information We Collect to help Us analyse how users use the Service, including by noting the third-party website from which you arrive. The information collected by the technology will be disclosed to or collected directly by these service providers, who use the information to evaluate your use of the Service. To prevent Google Analytics from using your information for analytics, you may install the <a className="fm-privacy-link" href="https://tools.google.com/dlpage/gaoptout">Google Analytics Opt-Out Browser Add-on</a>;</p>
              </li>
              <li className="fm-privacy-list-item">
                <p className="fm-privacy-list-text">other third parties (for anonymised statistical information);</p>
              </li>
              <li className="fm-privacy-list-item">
                <p className="fm-privacy-list-text">a person who can require Us to supply your personal information (e.g. a regulatory authority);</p>
              </li>
              <li className="fm-privacy-list-item">
                <p className="fm-privacy-list-text">a person We are required to disclose your information to by law or in the good faith belief that such action is necessary to (i) comply with a legal obligation, (ii) protect and defend the rights or property of Flamingo, including the enforcement of any legal terms governing your use of the Service, (iii) protect the personal safety of users of the Service or the public, or (iv) protect against legal liability;</p>
              </li>
              <li className="fm-privacy-list-item">
                <p className="fm-privacy-list-text">any other person authorised by the Act or another law (e.g. a law enforcement agency);</p>
              </li>
              <li className="fm-privacy-list-item">
                <p className="fm-privacy-list-text">a potential purchaser of some or all of the Flamingo business or company;</p>
              </li>
              <li className="fm-privacy-list-item">
                <p className="fm-privacy-list-text">any agents, officers or advisers of Flamingo; and</p>
              </li>
              <li className="fm-privacy-list-item">
                <p className="fm-privacy-list-text">any other person authorised by you.</p>
              </li>
            </ul>
            <p className="fm-privacy-list-text">A business that supports the Service may be located outside New Zealand. This may mean your personal information is held and processed outside New Zealand.</p>
          </div>

          <div className="fm-privacy-section">
            <p className="fm-privacy-list-header">How long We keep personal information</p>
            <p className="fm-privacy-list-text">Flamingo keeps your personal information for no longer than is reasonably necessary or required by law. How long We keep it depends very much on the type of information and purpose. Following termination or deactivation of your Account, Flamingo, its affiliates, and/or its service providers may retain information and user content for a commercially reasonable time for backup, archival, and/or audit purposes.</p>
            <p className="fm-privacy-list-text">The criteria We use to determine the retention period for certain categories of data is as follows:</p>
            <ul className="fm-privacy-list">
              <li className="fm-privacy-list-item">
                <p className="fm-privacy-list-text">the length of time that you have an Account;</p>
              </li>
              <li className="fm-privacy-list-item">
                <p className="fm-privacy-list-text">how frequently you rent Scooters from Us or when your most recent rental occurred;</p>
              </li>
              <li className="fm-privacy-list-item">
                <p className="fm-privacy-list-text">whether there are contractual or legal obligations that exist that require Us to retain the data for period of time;</p>
              </li>
              <li className="fm-privacy-list-item">
                <p className="fm-privacy-list-text">whether there is any ongoing legal claim that relates to any rental you have made with Us, or that is otherwise related to your relationship with Us;</p>
              </li>
              <li className="fm-privacy-list-item">
                <p className="fm-privacy-list-text">whether any applicable law, statute, or regulation allows for a specific retention period;</p>
              </li>
              <li className="fm-privacy-list-item">
                <p className="fm-privacy-list-text">whether the personal information is considered to be a special category of personal information, in which case a shorter retention period generally would be applied; and</p>
              </li>
              <li className="fm-privacy-list-item">
                <p className="fm-privacy-list-text">what the expectation for retention was at the time the data was provided to Us.</p>
              </li>
            </ul>
            <p className="fm-privacy-list-text">If you have any questions about termination or deactivation of your Account, please contact Us directly at legal@flamingoscooters.com.</p>
          </div>
          <div className="fm-privacy-section">
            <p className="fm-privacy-list-header">Protecting your personal information</p>
            <p className="fm-privacy-list-text">We will take reasonable steps to keep your personal information, including trip and destination data, safe from loss, unauthorised activity, or other misuse. However, no transmission of data over the Internet or email is ever fully secure or error free. In particular, email sent to or from the Service may not be secure.</p>
            <p className="fm-privacy-list-text">If Flamingo learns of a security systems breach, then We will notify you electronically through the contact information that you have provided to Flamingo so that you can take appropriate protective steps. Flamingo will also post a notice on the App and/or the Website if a security breach occurs.</p>
          </div>
          <div className="fm-privacy-section">
            <p className="fm-privacy-list-header">Links to other websites and services</p>
            <p className="fm-privacy-list-text">If you follow a link on Our Website and/or App to another site, the owner of that site will have its own privacy policy relating to your personal information. The links from the Service do not imply that Flamingo endorses or has reviewed the third-party sites. We suggest you review that site’s privacy policy before you provide personal information.</p>
          </div>
          <div className="fm-privacy-section">
            <p className="fm-privacy-list-header">Accessing and correcting your personal information</p>
            <p className="fm-privacy-list-text">Subject to certain grounds for refusal set out in the Act, you have the right to access your readily retrievable personal information that We hold and to request a correction to your personal information. Before you exercise this right, We will need evidence to confirm that you are the individual to whom the personal information relates.</p>
            <p className="fm-privacy-list-text">In respect of a request for correction, if We think the correction is reasonable and We are reasonably able to change the personal information, We will make the correction. If We do not make the correction, We will take reasonable steps to note on the personal information that you requested the correction.</p>
            <p className="fm-privacy-list-text">If you wish to exercise either of the above rights, email Us at legal@flamingoscooters.com. Your email should provide evidence of who you are and set out the details of your request (e.g. the personal information, or the correction, that you are requesting). We may charge you Our reasonable costs of providing to you copies of your personal information or correcting that information.</p>
          </div>
          <div className="fm-privacy-section">
            <p className="fm-privacy-list-header">Personal information about minors</p>
            <p className="fm-privacy-list-text">Our Website, App and Service is intended for persons eighteen (18) years of age or older. Although Scooters may be used by minors who are at least twelve (12) years of age, such use is under the sole responsibility of, and strict management by, the minor’s parent or legal guardian. We do not knowingly collect personal information from minors. If it comes to Our attention that We have collected personal information from or about a minor, We will delete it without notice. If you have reason to believe that We have collected personal information from or about a minor who is under eighteen (18) years of age, please contact Us by sending an email to legal@flamingoscooters.com.</p>
          </div>
          <div className="fm-privacy-section">
            <p className="fm-privacy-list-header">Consent to transfer</p>
            <p className="fm-privacy-list-text">We use computer systems that may be based in the United States, Australia and New Zealand, so some of your information could be processed in the United States, Australia and/or New Zealand. Data protection and privacy regulations may not offer the same level of protection as in other parts of the world. By using the Service, you agree to this policy and you consent to the transfer of all such information to the United States, Australia and/or New Zealand and to the processing of that information as described in this policy.</p>
          </div>
          <div className="fm-privacy-section">
            <p className="fm-privacy-list-header">Assignment</p>
            <p className="fm-privacy-list-text">We may change Our ownership of Flamingo while providing the Service. We may also sell certain assets associated with the Service. As a result, please be aware that in such event We may transfer some or all of your information to a business acquiring all or part of Our assets or to another business with which We have merged. Under such circumstances We would, to the extent possible, require the acquiring party to follow the practices described in this policy, as it may be amended from time to time. Nevertheless, We cannot promise that an acquiring business or the merged business will have the same privacy practices or treat your information the same as described in this policy.</p>
          </div>
          <div className="fm-privacy-section">
            <p className="fm-privacy-list-header">How to contact Us</p>
            <p className="fm-privacy-list-text">Your information is held by Flamingo Technologies Limited, a company incorporated in New Zealand. If you have any questions about the privacy of the Service or would like to make a complaint, email Us at legal@flamingoscooters.com. Our address is 46 Abel Smith Street, Te Aro, Wellington, 6011 , New Zealand.</p>
          </div>
        </div>
      </div>
    );
  }
}

export default Privacy;
