import React, { Component } from 'react';
import JoinPlanImage from './components/join-plan-image';
import JoinPlanBenefits from './components/join-plan-benefits';
import JoinPlanForm from './components/join-plan-form';
import Loading from '../common/loading';
import history from '../../helpers/history';
import api from '../../helpers/api';

class JoinPlan extends Component {
  constructor(props) {
    super(props);
    this.state = {
      plan: {},
      loading: true,
    };
    this.loadPlan = this.loadPlan.bind(this);
  }

  componentDidMount() {
    document.title = 'Workplace Invitation | Flamingo';
    this.loadPlan(this.props.match.params.plan);
  }

  loadPlan(slug) {
    return api.get(`/facade/get-plan/${slug}`)
      .then((res) => this.setState({ plan: res.data.data, loading: false }))
      .catch(() => history.push('/'));
  }

  render() {
    const { loading, plan } = this.state;

    if (loading) {
      return (
        <div className="fm-join-plan-loading">
          <Loading />
        </div>
      );
    }

    return (
      <div className="fm-join-plan">
        <JoinPlanImage name={plan.name} description={plan.description} />
        { !plan.description && <JoinPlanBenefits items={plan.benefits} /> }
        { plan.form && <JoinPlanForm /> }
      </div>
    );
  }
}

export default JoinPlan;
