import React, { Component } from 'react';

class HowToRide extends Component {
  render() {
    return (
      <div className="fm-home-how-to-ride">
        <div className="fm-home-how-to-ride-container">
          <p className="fm-home-how-to-ride-title">How to Ride</p>
          <div className="fm-home-how-to-ride-items">
            <div className="fm-home-how-to-ride-item">
              <div className="fm-home-how-to-ride-item-image" style={{ backgroundImage: `url('https://storage.googleapis.com/flamingo-static/images/facade/how-find.jpg')` }}></div>
              <p className="fm-home-how-to-ride-item-title">1. Find and Scan</p>
              <p className="fm-home-how-to-ride-item-description">Find Flamingo scooters through the Flamingo app. Scan the QR code on the top of the scooter within the app.</p>
            </div>
            <div className="fm-home-how-to-ride-item">
              <div className="fm-home-how-to-ride-item-image" style={{ backgroundImage: `url('https://storage.googleapis.com/flamingo-static/images/facade/how-ride.jpg')` }}></div>
              <p className="fm-home-how-to-ride-item-title">2. Start Riding</p>
              <p className="fm-home-how-to-ride-item-description">Push off with one foot, once you reach 5 km/h engage the throttle. Use the front and back brakes to stop.</p>
            </div>
            <div className="fm-home-how-to-ride-item">
              <div className="fm-home-how-to-ride-item-image" style={{ backgroundImage: `url('https://storage.googleapis.com/flamingo-static/images/facade/how-end.jpg')` }}></div>
              <p className="fm-home-how-to-ride-item-title">3. Park Considerately</p>
              <p className="fm-home-how-to-ride-item-description">Park your Flamingo to the side, ideally against street furniture. Keep paths and driveways clear.</p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default HowToRide;
