import React from 'react';

class Toast extends React.Component {
  render() {
    return (
      <div className="fm-toast">
        <p className="fm-toast-inner">{this.props.children}</p>
      </div>
    );
  }
}

export default Toast;
