import React, { Component } from 'react';

class PromoDetails extends Component {
  render() {
    const { promo } = this.props;
    return (
      <div className="fm-promo-details">
        <p className="fm-promo-details-title">{ promo.subheading }</p>
        <p className="fm-promo-details-description">{ promo.description }</p>
        {
          promo.download &&
          <div className="fm-promo-details-items">
            <a className="fm-promo-details-app" href="https://itunes.apple.com/nz/app/flamingo-scooters/id1446388027" target="_blank" rel="noopener noreferrer">
              <img className="fm-promo-details-app-img" src="/assets/images/appstore.svg" alt="Download Flamingo on the App Store" />
            </a>
            <a className="fm-promo-details-app" href="https://play.google.com/store/apps/details?id=com.flamingoscooters.android" target="_blank" rel="noopener noreferrer">
              <img className="fm-promo-details-app-img" src="/assets/images/playstore.svg" alt="Download Flamingo on the Play Store" />
            </a>
          </div>
        }
        {
          promo.button &&
          <a className="fm-promo-details-button" href={promo.button.url}>{promo.button.label}</a>
        }
        <p className="fm-promo-details-terms">{ promo.terms }</p>
      </div>
    );
  }
}

export default PromoDetails;
