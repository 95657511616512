import React, { Component } from 'react';
import Toast from '../../../common/toast';
import api from '../../../../helpers/api';

const options = [
  { key: 'general', label: 'General Enquiry', icon: 'general' },
  { key: 'trip', label: 'Rider Support', icon: 'user' },
  { key: 'report', label: 'Report a Scooter/Bike', icon: 'trip' },
  { key: 'incident', label: 'Report an Incident', icon: 'report' },
  // { key: 'media', label: 'Media Request' },
];

const messageLabel = {
  general: 'Message',
  trip: 'Message',
  report: 'Reason/Details',
  incident: 'Incident Details',
};

class ContactForm extends Component {
  constructor() {
    super();
    this.state = {
      loading: false,
      type: 'general',
      name: '',
      email: '',
      message: '',
      location: '',
      registration: '',
    };

    this.handleType = this.handleType.bind(this);
    this.nameChange = this.nameChange.bind(this);
    this.emailChange = this.emailChange.bind(this);
    this.messageChange = this.messageChange.bind(this);
    this.locationChange = this.locationChange.bind(this);
    this.registrationChange = this.registrationChange.bind(this);
    this.submit = this.submit.bind(this);
    this.renderOption = this.renderOption.bind(this);
  }

  handleType(type) {
    this.setState({ type });
  }

  nameChange(e) {
    this.setState({ name: e.target.value, error: false });
  }

  emailChange(e) {
    this.setState({ email: e.target.value, error: false });
  }

  messageChange(e) {
    this.setState({ message: e.target.value, error: false });
  }

  locationChange(e) {
    this.setState({ location: e.target.value, error: false });
  }

  registrationChange(e) {
    this.setState({ registration: e.target.value, error: false });
  }

  submit(e) {
    e.preventDefault();
    const {
      type,
      name,
      email,
      message,
      location,
      registration,
    } = this.state;

    const data = {
      type,
      name,
      email,
      message,
    };

    if (['report', 'incident'].includes(type) && location.length > 0) {
      data.location = location;
    }

    if (['report', 'incident'].includes(type) && registration.length > 0) {
      data.registration = registration;
    }

    this.setState({ loading: true, error: false });
    api.post('/facade/contact', data)
      .then((res) => this.setState({ success: true, loading: false }))
      .catch((e) => this.setState({ error: this.parseApiError(e), loading: false }));
  }

  parseApiError(err) {
    const errorCodes = {
      'validation': 'Invalid name, email or message',
      'domain_not_found': 'Email address not applicable',
    };
    return window.access(() => err.response.data.code) && errorCodes[err.response.data.code] ? errorCodes[err.response.data.code] : 'Something went wrong';
  }

  renderOption(option, i) {
    const className = this.state.type === option.key ? 'fm-contact-form-option fm-contact-form-option-selected' : 'fm-contact-form-option';
    return (
      <div className={className} onClick={() => this.handleType(option.key)} key={i}>
        <img className="fm-contact-form-option-img" alt={ option.label } src={`https://storage.googleapis.com/flamingo-static/images/facade/contact-${ option.icon }.svg`} />
        <p className="fm-contact-form-option-label">{ option.label }</p>
      </div>
    );
  }

  render() {
    const {
      type,
      loading,
      success,
      error,
      name,
      email,
      message,
      location,
      registration,
    } = this.state;

    if (success) {
      return (
        <div className="fm-contact-form" >
          <p className="fm-contact-form-intro">Thank you for contacting us. We will be in touch within 24 hours.</p>
        </div>
      );
    }

    return (
      <form className="fm-contact-form" onSubmit={this.submit}>
        <p className="fm-contact-form-intro">We're here to help! You can contact us by phone, email or using the form below. Just select the option that best describes your query so we can direct it to the right team.</p>
        <div className="fm-contact-form-options">
          { options.map(this.renderOption) }
        </div>
        { type === 'trip' && <p className="fm-contact-form-warning">If you are unable to end your ride, please call us for immediate support.</p> }
        <div className="fm-contact-form-input-container">
          <p className="fm-contact-form-input-label">Name</p>
          <input className="fm-contact-form-input" type="text" disabled={loading} placeholder="Eg. Joe Flamingo" onChange={this.nameChange} value={name} />
        </div>
        <div className="fm-contact-form-input-container">
          <p className="fm-contact-form-input-label">Email</p>
          <input className="fm-contact-form-input" type="email" disabled={loading} placeholder="Eg. you@example.com" onChange={this.emailChange} value={email} />
        </div>
        {
          type === 'report' &&
          <>
            <div className="fm-contact-form-input-container">
              <p className="fm-contact-form-input-label">Scooter/Bike Identifier Number (Optional)</p>
              <input className="fm-contact-form-input" type="text" disabled={loading} placeholder="The 4 digit number on the side of the scooter/bike." onChange={this.registrationChange} value={registration} />
            </div>
            <div className="fm-contact-form-input-container">
              <p className="fm-contact-form-input-label">Location of Scooter/Bike</p>
              <input className="fm-contact-form-input" type="text" disabled={loading} placeholder="Eg. 123 Taranaki St, Wellington" onChange={this.locationChange} value={location} />
            </div>
          </>
        }
        {
          type === 'incident' &&
          <>
            <div className="fm-contact-form-input-container">
              <p className="fm-contact-form-input-label">Scooter/Bike Identifier Number (Optional)</p>
              <input className="fm-contact-form-input" type="text" disabled={loading} placeholder="The 4 digit number on the side of the scooter/bike." onChange={this.registrationChange} value={registration} />
            </div>
            <div className="fm-contact-form-input-container">
              <p className="fm-contact-form-input-label">Location of Incident</p>
              <input className="fm-contact-form-input" type="text" disabled={loading} placeholder="Eg. 123 Taranaki St, Wellington" onChange={this.locationChange} value={location} />
            </div>
          </>
        }
        <div className="fm-contact-form-input-container">
          <p className="fm-contact-form-input-label">{ messageLabel[type] || 'Message' }</p>
          <textarea className="fm-contact-form-textarea" rows={5} disabled={loading} placeholder="Type here..." onChange={this.messageChange} value={message} />
        </div>
        <input className="fm-contact-form-button" disabled={loading} type="submit" value={ loading ? 'Loading...' : 'Submit' } />
        { error && <Toast>{ error }</Toast> }
      </form>
    );
  }
}

export default ContactForm;
