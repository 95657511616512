import React, { Component } from 'react';
import Toast from '../../../common/toast';

import api from '../../../../helpers/api';

class JoinPlanForm extends Component {
  constructor() {
    super();
    this.state = { loading: false, email: '', phone: '', error: false, success: false };

    this.emailChange = this.emailChange.bind(this);
    this.phoneChange = this.phoneChange.bind(this);
    this.submit = this.submit.bind(this);
  }

  emailChange(e) {
    this.setState({ email: e.target.value, error: false });
  }

  phoneChange(e) {
    this.setState({ phone: e.target.value, error: false });
  }

  submit(e) {
    e.preventDefault();
    const { email, phone } = this.state;

    this.setState({ loading: true, error: false });
    api.post('/facade/join-plan', { email, phone })
      .then((res) => this.setState({ success: true, loading: false }))
      .catch((e) => this.setState({ error: this.parseApiError(e), loading: false }));
  }

  parseApiError(err) {
    const errorCodes = {
      'validation': 'Invalid email or phone',
      'domain_not_found': 'Email address not applicable',
    };
    return window.access(() => err.response.data.code) && errorCodes[err.response.data.code] ? errorCodes[err.response.data.code] : 'Something went wrong';
  }

  render() {
    const { loading, email, phone, success, error } = this.state;

    return (
      <div className="fm-join-plan-form">
        <p className="fm-join-plan-form-title">Verify Your Account</p>
        {
          success ? (
            <p className="fm-join-plan-form-description">Thank you! Please check your email { email } to complete verification. If you have not received an email within 5 minutes, please check that the phone number you provided is setup on Flamingo.</p>
          ) : (
            <>
              <p className="fm-join-plan-form-description">To continue, please enter your approved work email address along with the phone number on your Flamingo account. A verification email will be sent to this address. You must have already created a Flamingo account before completing this verification. The email address on your Flamingo account can be a personal address.</p>
              <form className="fm-join-plan-form-form" onSubmit={this.submit}>
                <div className="fm-join-plan-form-inputs">
                  <div className="fm-join-plan-form-input-container">
                    <p className="fm-join-plan-form-input-label">Work Email</p>
                    <input className="fm-join-plan-form-input" type="email" disabled={loading} placeholder="Eg. you@work.com" onChange={this.emailChange} value={email} />
                  </div>
                  <div className="fm-join-plan-form-input-container">
                    <p className="fm-join-plan-form-input-label">Mobile Number</p>
                    <input className="fm-join-plan-form-input" type="text" disabled={loading} placeholder="Eg. 021 123 4567" onChange={this.phoneChange} value={phone} />
                  </div>
                </div>
                <input className="fm-join-plan-form-button" disabled={loading} type="submit" value={ loading ? 'Loading...' : 'Verify Email' } />
              </form>
            </>
          )
        }
        { error && <Toast>{error}</Toast> }
      </div>
    );
  }
}

export default JoinPlanForm;
