import React, { Component } from 'react';
import Map, { Polygon } from '../../../common/map';

class ServiceArea extends Component {
  render() {
    const { city, region, loading } = this.props;
    const mapOptions = { center: city.center, zoom: city.zoom };

    return (
      <div className="fm-city-service-area">
        <div className="fm-city-service-area-map">
          <Map options={mapOptions} google={window.google}>
            { !loading && <Polygon paths={region.serviceArea.map(i => ({ lat: i.latitude, lng: i.longitude }))} fillColor="#FF206E" strokeColor="#FF206E" /> }
          </Map>
        </div>
        <div className="fm-city-service-area-container">
          <p className="fm-city-service-area-title">{ city.name } Service Area</p>
          <p className="fm-city-service-area-text">Hire, ride and park your Flamingo within the pink boundary. Check the Flamingo app for No Parking and No Riding Zones as well as city specific rules.</p>
        </div>
      </div>
    );
  }
}

export default ServiceArea;
