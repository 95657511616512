import React, { Component } from 'react';
import Loading from '../common/loading';

import api from '../../helpers/api';

class VerifyPlan extends Component {
  constructor() {
    super();
    this.state = { loading: true, result: false };

    this.verifyToken = this.verifyToken.bind(this);
  }

  componentDidMount() {
    document.title = 'Workplace Verification | Flamingo';

    this.verifyToken();
  }

  verifyToken() {
    const token = this.props.match.params.token;
    api.post('/facade/verify-plan', { token })
      .then((res) => this.setState({ result: `Thank you for verifying your email! You're all set to go! You can track your weekly usage under the "Go Pass" section of the Flamingo app.`, loading: false }))
      .catch((e) => this.setState({ result: this.parseApiError(e), loading: false }));
  }

  parseApiError(err) {
    const errorCodes = {
      'validation': 'The email verification token was invalid. Please try again.',
      'verification_not_found': 'The email verification token was not found. Please try again.',
      'verification_expired': 'The email verification token has expired. Please try again.',
    };
    return window.access(() => err.response.data.code) && errorCodes[err.response.data.code] ? errorCodes[err.response.data.code] : 'Something went wrong. Please try again.';
  }

  render() {
    const { loading, result } = this.state;
    if (loading) {
      return (
        <div className="fm-verify-plan">
          <Loading />
        </div>
      );
    }
    return (
      <div className="fm-verify-plan">
        <div className="fm-verify-plan-image">
          <div className="fm-verify-plan-image-background">
            <div className="fm-verify-plan-image-background-box"></div>
            <div className="fm-verify-plan-image-background-img" style={{ backgroundImage: `url('https://storage.googleapis.com/flamingo-static/images/facade/join-plan.jpg')` }}></div>
          </div>
          <div className="fm-verify-plan-image-content">
            <div className="fm-verify-plan-image-content-box">
              <p className="fm-verify-plan-image-title">Workplace Verification</p>
              <p className="fm-verify-plan-image-description">{ result }</p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default VerifyPlan;
