import React, { Component } from 'react';
import AboutImage from './components/about-image';
import AboutBlurb from './components/about-blurb';
import AboutSustainability from './components/about-sustainability';
import AboutBenefits from './components/about-benefits';
import AboutFollow from './components/about-follow';

class About extends Component {
  componentDidMount() {
    document.title = 'About | Flamingo';
  }

  render() {
    return (
      <div className="fm-about">
        <AboutImage />
        <AboutBlurb />
        <AboutSustainability />
        <AboutBenefits />
        <AboutFollow />
      </div>
    );
  }
}

export default About;
