import React, { Component } from 'react';
import Markdown from 'markdown-to-jsx';
import PageHeading from '../common/page-heading';
import Loading from '../common/loading';
import history from '../../helpers/history';
import api from '../../helpers/api';

class Careers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      careers: [],
      loading: true,
    };
    this.loadCareers = this.loadCareers.bind(this);
  }

  componentDidMount() {
    document.title = 'Careers | Flamingo';
    this.loadCareers();
  }

  loadCareers() {
    return api.get(`/facade/career`)
      .then((res) => this.setState({ careers: res.data.data, loading: false }))
      .catch(() => history.push('/'));
  }

  renderCareer(career, i) {
    return (
      <div className="fm-career" key={i} id={`fm-career-item-${career.id}`}>
        <p className="fm-career-title">{ career.title }</p>
        <div className="fm-career-container">
          <Markdown className="fm-career-details">{ career.details }</Markdown>
          <a className="fm-career-apply" target="_blank" rel="noreferrer" href={ career.applyUrl || 'mailto:careers@flamingo.co.nz' }>Apply Now</a>
        </div>
      </div>
    );
  }

  renderCareerLink(career, i) {
    return (
      <li className="fm-career-list-item" key={i}>
        <a className="fm-career-link" href={`#fm-career-item-${career.id}`}>{ career.title }</a>
      </li>
    );
  }

  render() {
    const { loading, careers } = this.state;
    return (
      <div className="fm-careers">
        <PageHeading image="https://storage.googleapis.com/flamingo-static/images/facade/flying.jpg" title="Careers" />
        <div className="fm-container fm-careers-content">
          {
            loading ? (
              <div className="fm-careers-loading">
                <Loading />
              </div>
            ) : (
              <>
                <p className="fm-careers-summary-title">Current Openings</p>
                <ul className="fm-careers-summary">
                  { careers.map(this.renderCareerLink) }
                </ul>
                { careers.length === 0 && <p className="fm-careers-none">There are no current openings.</p> }
                <div className="fm-careers-items">
                  { careers.map(this.renderCareer) }
                </div>
              </>
            )
          }
        </div>
      </div>
    );
  }
}

export default Careers;
