import React, { Component } from 'react';

class SafetyVideos extends Component {
  constructor() {
    super();
    this.state = { videoPlayer: false };
    this.videoBackground = React.createRef();
    this.playVideo = this.playVideo.bind(this);
    this.handleVideoBackgroundClick = this.handleVideoBackgroundClick.bind(this);
  }

  playVideo(videoPlayer) {
    this.setState({ videoPlayer });
  }

  handleVideoBackgroundClick(e) {
    if (e.target === this.videoBackground.current) {
      this.setState({ videoPlayer: false });
    }
  }

  render() {
    return (
      <>
        <div className="fm-safety-videos-container">
          <p className="fm-safety-videos-title">Safety Videos</p>
          <div className="fm-safety-videos-items">
            <div className="fm-safety-videos-item" onClick={() => this.playVideo('4U1EXFf5QxE')}>
              <div className="fm-safety-videos-item-image" style={{ backgroundImage: `url('https://storage.googleapis.com/flamingo-static/images/facade/starting-ride.jpg')` }}>
                <div className="fm-safety-videos-item-play"></div>
              </div>
              <p className="fm-safety-videos-item-title">Starting Your Ride</p>
              <p className="fm-safety-videos-item-description">First time riding with Flamingo? Watch this video to learn how to get started.</p>
            </div>
            <div className="fm-safety-videos-item" onClick={() => this.playVideo('uIQ51aAmmYg')}>
              <div className="fm-safety-videos-item-image" style={{ backgroundImage: `url('https://storage.googleapis.com/flamingo-static/images/facade/safe-riding.jpg')` }}>
                <div className="fm-safety-videos-item-play"></div>
              </div>
              <p className="fm-safety-videos-item-title">Safe Riding</p>
              <p className="fm-safety-videos-item-description">How to enjoy Flamingo safely and stay mindful of your surroundings.</p>
            </div>
            <div className="fm-safety-videos-item" onClick={() => this.playVideo('UO-Q7TGBVUg')}>
              <div className="fm-safety-videos-item-image" style={{ backgroundImage: `url('https://storage.googleapis.com/flamingo-static/images/facade/ending-ride.jpg')` }}>
                <div className="fm-safety-videos-item-play"></div>
              </div>
              <p className="fm-safety-videos-item-title">Ending Your Ride</p>
              <p className="fm-safety-videos-item-description">Finished riding? Learn how to end your ride and park safely.</p>
            </div>
          </div>
        </div>
        { this.state.videoPlayer &&
          <div className="fm-safety-video-player-background" ref={this.videoBackground} onClick={this.handleVideoBackgroundClick}>
            <div className="fm-safety-video-player-box">
              <iframe title="Flamingo Safety Video" width="560" height="315" src={ `https://www.youtube.com/embed/${this.state.videoPlayer}?autoplay=1&rel=0&modestbranding=1&autohide=1&showinfo=0&controls=0&loop=1` } frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              <p className="fm-safety-video-player-box-close">CLICK HERE TO CLOSE</p>
            </div>
          </div>
        }
      </>
    );
  }
}

export default SafetyVideos;
